import { Tabs } from 'react-tabs'
import styled from 'styled-components'

import { Spacing } from '../styles/spacing'
import { darkBase, mdWidth, primaryAccent, primaryBase, smWidth } from '../styles/theme'

export const MyAccountTabs = styled(Tabs)`
    max-width: 100%;
    width: 100%;

    .react-tabs__tab-list {
        list-style: none;
        text-align: center;
        font-size: 1.2em;
        font-weight: bold;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: row;

        @media (max-width: ${smWidth}) {
            flex-direction: column;
        }

        .react-tabs__tab {
            display: inline-block;
            padding: ${Spacing.Small} ${Spacing.ExtraLarge};
            border-left: 1px solid ${darkBase};
            cursor: pointer;
            text-transform: uppercase;
            transition: color 0.5s;
            font-size: 1rem;

            @media (max-width: ${smWidth}) {
                border: none;
            }
            @media (max-width: ${mdWidth}) {
                padding: ${Spacing.Small} ${Spacing.Medium};
            }

            &:first-child {
                border: none;
            }

            &::after {
                content: '';
                width: calc(100% - #{2 * ${Spacing.ExtraLarge} - 2px});
                border-bottom: 3px solid ${primaryAccent};
                position: absolute;
                bottom: ${Spacing.Small} - 2px;
                left: ${Spacing.ExtraLarge} - 1px;
                opacity: 0;
                transition: opacity 0.5s;
            }

            &.react-tabs__tab--selected {
                position: relative;
                color: ${primaryBase};

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .react-tabs__tab-panel {
        opacity: 0;
        transition: opacity 0.5s;

        &.react-tabs__tab-panel--selected {
            opacity: 1;
        }
    }
`
