import * as React from 'react'

import { compareAsc } from 'date-fns'

import { BudgetBillingInformation } from '@igs-web/common-models/models/budget-billing'
import { BarChart } from '@igs-web/common-ui-components/_molecules/charts/bar-chart'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { asDate, getMonth } from '@igs-web/common-utilities/utilities/date-utilities'

const getValueFromCustomProperty = (property: string): string => {
    return window.getComputedStyle(document.documentElement).getPropertyValue(property)
}

export const BudgetBillingChart = ({ budgetBillInfo }: Props) => {
    const dataSorted = [...budgetBillInfo].sort((a, b) => compareAsc(asDate(a.billMonth), asDate(b.billMonth)))
    const xLabel = 'MONTH'
    const yLabel = 'AMOUNT'

    const datasets = [
        {
            type: 'line',
            borderColor: getValueFromCustomProperty('--primary-light'),
            backgroundColor: 'transparent',
            label: 'Budget Billing Amount',
            data: dataSorted.map(x => x.budgetAmount),
        },
        {
            type: 'bar',
            backgroundColor: getValueFromCustomProperty('--primary-base'),
            label: 'Total Charges',
            data: dataSorted.map(x => x.totalCharges),
        },
    ]

    const chartData: Chart.ChartData = {
        datasets,
        labels: dataSorted.map(x => getMonth(x.billMonth).abbreviation),
    }

    const chartOptions: Chart.ChartOptions = {
        tooltips: {
            callbacks: {
                label: tooltipItem => {
                    if (tooltipItem && tooltipItem.value) {
                        return formatAsMoney(Number(tooltipItem.value))
                    }
                    return ''
                },
            },
        },
    }

    return (
        <div data-testid="budget-billing-chart" className="hide-on-mobile" style={{ height: '300px', width: '100%' }}>
            <BarChart data={chartData} xLabel={xLabel} yLabel={yLabel} options={chartOptions} />
        </div>
    )
}

interface Props {
    readonly budgetBillInfo: ReadonlyArray<BudgetBillingInformation>
}
