import * as React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components'

import { ActiveScanaRegulatedGasCustomerWithScanaHwAlert } from '@igs-web/common-components/domain/authentication/multiple-membership-alert'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { MyAccountDashboardContainer } from '@igs-web/common-components/domain/myaccount/myaccount-dashboard-container'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { NoWrapText } from '@igs-web/common-ui-components/_atoms/typography'
import { ContainerGrid, GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { ProfileReloader } from 'domain/dashboard/profile-reloader'
import * as Routes from 'domain/routes'

const AlertContainer = styled(ContainerGrid)`
    place-items: center;
`

export const Dashboard = () => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))
    const { externalWebsite } = useCompany()
    const showNoAccountsMessage = !userProfile || userProfile.serviceAddresses.length < 1 || userProfile.billingAccounts.length < 1
    const isActiveScanaRegCustomerWithHw = userProfile && userProfile.hasActiveScanaRegGasAccount && userProfile.hasScanaHW

    const location = useLocation()
    const header = location.pathname === Routes.AccountsAndBalances ? 'Accounts & Balances' : 'Account Summary'

    return (
        <>
            <ScrollToTop />
            <NavigationBanner title={header} />
            <PageContentContainer>
                {showNoAccountsMessage && !isActiveScanaRegCustomerWithHw && (
                    <>
                        <GridSpacer spacing={Spacing.Large} />
                        <AlertContainer maxWidth={Breakpoint.Desktop}>
                            <InfoText>
                                We could not find a natural gas account associated with this email address. If you recently requested service, it may take 24 -
                                48 hours for your online account to be set up. To sign up for new service, visit our{' '}
                                <NoWrapText>
                                    <Link href={`${externalWebsite}/rates-sign-up`}>Rates & Sign Up</Link> page.
                                </NoWrapText>
                            </InfoText>
                        </AlertContainer>
                        <GridSpacer spacing={Spacing.Large} />
                    </>
                )}
                {showNoAccountsMessage && isActiveScanaRegCustomerWithHw && (
                    <>
                        <GridSpacer spacing={Spacing.Large} />
                        <AlertContainer maxWidth={Breakpoint.Desktop}>
                            <ActiveScanaRegulatedGasCustomerWithScanaHwAlert />
                        </AlertContainer>
                        <GridSpacer spacing={Spacing.Large} />
                    </>
                )}
                {userProfile && !showNoAccountsMessage && <MyAccountDashboardContainer userProfile={userProfile} />}
                <ProfileReloader />
            </PageContentContainer>
        </>
    )
}
