import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { StyledApp } from 'scana/styles/app-styles'
import { ThemeProvider } from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { AppOfflineAlerter } from '@igs-web/common-components/domain/errors/app-offline-alerter'
import { ScreenSharePage } from '@igs-web/common-components/domain/genesys-co-browse/screen-share-page'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { EncodedHtml } from '@igs-web/common-ui-components/_atoms/encode-html'
import { FullWidthMessageBanner } from '@igs-web/common-ui-components/_molecules/banners/message-banner'
import { StatefulLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { defaultTheme } from '@igs-web/common-ui-components/styles/theming/default-theme'
import { SiteMessage, getSiteMessage } from '@igs-web/common-utilities/services/site-message-service'

import 'root/global-error-logger'

import { ScanaFooter } from 'molecules/footer/footer'
import { MyAccountHeader } from 'molecules/header/header'
import { ProtectedRoute } from 'molecules/routing/protected-route'

import { LoginPage } from 'domain/authentication/login-page'
import { ContactUsPage } from 'domain/contact-us/contact-us-page'
import { GasFAQPage } from 'domain/myaccount/gas-FAQ-page'
import { BillPayResponse } from 'domain/payment/pay-bill-response'
import * as Routes from 'domain/routes'

import { RegulatedMyAccountRouter } from './domain/myaccount/regulated-myaccount-routes'

const App = () => {
    const [siteMessage, setSiteMessage] = React.useState<null | SiteMessage>(null)
    const company = useCompany()
    const isLoggedIn = useSelector(UserSelectors.selectLoggedIn)

    useEffectOnce(() => {
        const checkMessage = async () => {
            const message = await getSiteMessage(company)
            setSiteMessage(message)
        }
        checkMessage()
    })

    return (
        <StyledApp maxWidth={Breakpoint.Desktop}>
            <StatefulLoader />
            <AppOfflineAlerter />
            <ThemeProvider theme={defaultTheme}>
                {siteMessage && (
                    <FullWidthMessageBanner
                        id={siteMessage.id}
                        isDismissable={siteMessage.isDismissable}
                        endDate={siteMessage.endDate}
                        dataTestId="site-message-banner"
                    >
                        <EncodedHtml content={siteMessage.message} />
                    </FullWidthMessageBanner>
                )}
                <MyAccountHeader />
                <Switch>
                    <Route exact={true} path={Routes.BillPaid}>
                        <BillPayResponse />
                    </Route>
                    <ProtectedRoute path={Routes.MyAccountRoute}>
                        <RegulatedMyAccountRouter />
                    </ProtectedRoute>
                    <Route path={Routes.HelpRoute}>
                        <GasFAQPage />
                    </Route>
                    <Route path={Routes.ContactUsRoutes}>
                        <ContactUsPage />
                    </Route>
                    <Route path={Routes.ScreenShare}>
                        <ScreenSharePage />
                    </Route>
                    {isLoggedIn && <Route component={RegulatedMyAccountRouter} />}
                    <Route component={LoginPage} />
                </Switch>
                <ScanaFooter />
            </ThemeProvider>
        </StyledApp>
    )
}

export default App
