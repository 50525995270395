import { MembershipApplication } from '@igs-web/common-models/constants/membership-application'
import { StateCode } from '@igs-web/common-models/constants/states'
import { PricingTier } from '@igs-web/common-models/models/offer-model'
import { MoneyFormat } from '@igs-web/common-utilities/utilities/currency-utilities'
import { Environment, getEnvironment } from '@igs-web/common-utilities/utilities/environment-utilities'

import { BaseCompany, SupportedCompanies } from '../company'
import { defaultCompanyFeatures } from '../companyFeatures'
import { defaultEnrollmentFeatures } from '../enrollmentFeatures'
import { defaultRouteFeatures } from '../routeFeatures'

import { scanaRegulatedVerbiage } from './scana-regulated-verbiage'

const getExternalWebsite = () => {
    const baseUrl = 'scanaenergyregulated'
    const env = getEnvironment()
    switch (env) {
        case Environment.Prod:
            return 'https://scanaenergyregulated.com'
        case Environment.Qa:
            return `https://${baseUrl}.qa.igsenergy.net`
        default:
            return `https://${baseUrl}.${env}.igsenergy.net`
    }
}

const getWebsite = (baseUrl: string) => {
    const env = getEnvironment()
    switch (env) {
        case Environment.Prod:
            return `https://account.${baseUrl}.com`
        case Environment.Local:
            return 'https://localhost:9000'
        default:
            return `https://account-${baseUrl}.${env}.igsenergy.net`
    }
}

const externalWebsite = getExternalWebsite()

export const scanaRegulatedCompany = (): BaseCompany => ({
    name: SupportedCompanies.ScanaRegulated,
    displayName: 'SCANA Energy Regulated',
    displayNameByLob: () => 'SCANA Energy Regulated',
    myAccountUrl: getWebsite('scanaenergyregulated'),
    membershipApplicationName: MembershipApplication.ScanaRegulated,
    externalWebsite,
    address: 'SCANA Energy, P.O. Box 100157, Columbia, SC 29202-3157',
    lockboxPaymentCompanyName: 'SCANA Energy Regulated',
    lockboxPaymentPOBox: 'P.O. Box 105046',
    lockboxPaymentCityStateZip: 'Atlanta, GA 30348-5046',
    businessHours: {
        businessHoursFull: 'Monday-Friday 7am-8pm, Saturday 8am-5pm',
        businessHoursAbbreviated: 'Mon - Fri, 7am to 8pm | Sat, 8am to 5pm',
        businessHoursAbbreviatedWeek: 'Mon - Fri, 7am to 8pm',
        businessHoursAbbreviatedWeekend: 'Sat, 8am to 5pm',
        homwWarrantyAbbreviated: 'Mon - Fri, 8am to 6pm',
        homeWarrantyFull: 'Monday-Friday 8am-6pm',
    },
    emails: {
        customerSupport: '',
        solarSupport: '',
        communitySolar: '',
        homeWarranty: '',
    },
    phoneNumber: {
        help: '18662457742',
        gasLeak: '18774274321',
        heatProgram: '18663538640',
        customerSupport: '18662457742',
        commecialSupport: '8886218828',
        payment: '18662457742',
        communitySolar: '',
        solarSupport: '',
        homeWarranty: '',
        supportFax: '',
    },
    verbiage: { ...scanaRegulatedVerbiage },
    graphics: {
        colorLogo: '/assets/scana-energy-regulated-logo.svg',
        monochromeLogo: '/assets/scana-energy-regulated-logo.svg',
        loginBackground: '',
    },
    features: {
        ...defaultCompanyFeatures,
        displayPlanAtAGlanceTerminationFee: false,
        displayPlanAtAGlanceProgressCircle: false,
        displayMoreTimeToPay: true,
        displayUtilityInCustomerInformation: false,
        displayDashboardProspects: false,
        canEnrollInHeat: false,
        canMyAccountRateChange: false,
        canMyAccountRenew: false,
        canChangeNameFromProfile: false,
        hasSolarMyAccount: false,
        canAddAndRemoveAccounts: false,
        preferRoutingOverHref: true,
        displayPaymentHistoryChart: true,
        hasOnlineChat: false,
        displayLoginPageSubheader: false,
        canMakeServiceRequest: false,
        canEnrollOnline: false,
        useDashboardScrollIntoView: false,
        canRenewAnyStatus: false,
        canManagePaymentsAndBilling: true,
        displayDataNotAvailableMessage: false,
        useLegacydata: true,
        dataStartDate: new Date('October 08, 2022'),
        displayLegacyDataMessage: true,
        useBillingAccounts: true,
        canCreateOnlinePaymentExtension: true,
        variableRateFormatDecimalPlaces: 4,
        variablRateFormatMoneyPrecision: 4,
        variablRateMoneyFormat: MoneyFormat.Dollars,
    },
    includeNonBreakingHyphenInTermMonths: false,
    includeLobTitleInProductTitle: false,
    useCompanyCommodityTitle: true,
    enrollment: {
        ...defaultEnrollmentFeatures,
        defaultState: StateCode.GA,
        defaultZipCode: '30306',
        collectAlternatePhone: false,
        affirmCreditCheckInfo: true,
        suppressProductsOnSummary: true,
        loadDiscountAsSeparateCall: false,
        canEnrollFromPublicWebsite: false,
        collectInitialsOnSummary: false,
    },
    routes: {
        ...defaultRouteFeatures,
        general: {
            faq: `${externalWebsite}/contact`,
        },
        product: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            termsAndConditions: (productCode, accountType, zipCode?, offerCode?, campaignCode?, priceTier?) => {
                if (externalWebsite) {
                    if (priceTier?.toUpperCase() === PricingTier.Group1) {
                        return `${externalWebsite}/sign-up-for-service/rates/low-income-terms`
                    } else if (priceTier?.toUpperCase() === PricingTier.Group2) {
                        return `${externalWebsite}/sign-up-for-service/rates/credit-challenged-terms`
                    }
                    return `${externalWebsite}/terms-conditions`
                } else {
                    console.debug(`${productCode}, ${accountType} ${zipCode}, ${offerCode}, ${campaignCode}, ${priceTier}`)
                }
            },
            productSelection: () => {
                return `${getWebsite('scanaenergyregulated')}/MyAccount/AccountUpdate`
            },
        },
        payment: {
            ...defaultRouteFeatures.payment,
            nonProdPaymentPortalRegistered: 'https://odweb.clienttestmatrix.com/IGSScanaRegulated/Payment/PostData',
            prodPaymentPortalRegistered: 'https://webpayments.billmatrix.com/IGSScanaRegulated/Payment/PostData',
            nonProdPaymentPortalGuest: 'https://odweb.clienttestmatrix.com/IGSScanaRegulatedGuest',
            prodPaymentPortalGuest: 'https://webpayments.billmatrix.com/IGSScanaRegulatedGuest',
        },
    },
    companionWebsite: {
        displayName: 'SCANA Energy',
        myAccountUrl: getWebsite('scanaenergy'),
    },
})
