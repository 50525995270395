import { BaseCompany } from '@igs-web/common-components/domain/company/company'
import { PriceUnit } from '@igs-web/common-models/constants/price-units'
import { LineOfBusinessCode, LineOfBusinessConstant } from '@igs-web/common-models/models/line-of-business'
import { ProductModel, ProductTypeCode } from '@igs-web/common-models/models/offer-model'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'

import { MONTHS_PER_YEAR, asDate, getDurationInMonths, getMonth, getMonthName } from './date-utilities'
import { roundToMulitple } from './number-utilites'
import { nonBreakingHyphen } from './string-formatter'

export interface OfferForTitle {
    readonly primaryProduct: ProductForTitle
}

export interface ProductForTitle {
    readonly title?: string
    readonly productType: ProductTypeCode
    readonly discountCode?: string | null | undefined
    readonly termMonths?: number | null | undefined
    readonly termEndMonth?: number
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly isGreen?: boolean
    readonly isFriendsAndFamily?: boolean
    readonly ratePlanName?: string
    readonly campaignCode: string
}

export const getProductType = (product: ProductForTitle) => {
    const lob = LineOfBusinessConstant.getByCode(product.lineOfBusinessCode)

    if (!lob.isCommodity) {
        return 'Fixed'
    }

    if (product.isFriendsAndFamily) {
        return 'Friends & Family'
    }

    switch (product.productType) {
        case ProductTypeCode.Fixed:
            return 'Fixed'
        case ProductTypeCode.Variable:
            return 'Variable'
        default:
            return ''
    }
}

export const getProductKey = (product: ProductModel) => {
    const lob = LineOfBusinessConstant.getByCode(product.lineOfBusinessCode)

    if (!lob.isCommodity) {
        return product.productCode
    }

    if (product.isFriendsAndFamily) {
        return 'FAF'
    }
    switch (product.productType) {
        case ProductTypeCode.Fixed:
            return product.termMonths?.toString() || '12'
        case ProductTypeCode.Variable:
            return 'VARIABLE'
        default:
            return 'UNKNOWN'
    }
}

export const getDiscountType = (product: ProductForTitle) => {
    if (product.discountCode) {
        switch (product.discountCode) {
            case 'GENERICELECTRIC':
                return 'BUNDLED'
            default:
                return 'Discounted'
        }
    }
    return ''
}

const getCommodityTitle = (product: ProductForTitle, company: BaseCompany, includeGreenBranding: boolean) => {
    const { includeNonBreakingHyphenInTermMonths, includeLobTitleInProductTitle, useCompanyCommodityTitle } = company
    if (useCompanyCommodityTitle) {
        return getCompanyCommodityTitle(product, company)
    }
    const hyphen = includeNonBreakingHyphenInTermMonths ? nonBreakingHyphen : ' '
    const { termMonths, termEndMonth } = product
    const productType = getProductType(product)
    const lobTitle = includeLobTitleInProductTitle ? ' ' + LineOfBusinessConstant.getByCode(product.lineOfBusinessCode).title : ''
    const discount = getDiscountType(product)
    const greenMessaging =
        includeGreenBranding && product.isGreen ? (product.lineOfBusinessCode === LineOfBusinessConstant.gas.code ? 'Carbon-Neutral ' : 'Green ') : ''

    if (termMonths) {
        return `${termMonths}${hyphen}Month ${discount} ${greenMessaging} ${productType}${lobTitle}`
    }

    if (termEndMonth) {
        const monthName = getMonthName(termEndMonth)
        const year = asDate(termEndMonth).getFullYear()
        return `${greenMessaging}${productType}${lobTitle} Until ${monthName} ${year}`
    }

    return `${greenMessaging} ${productType} Rate`
}

const getCompanyCommodityTitle = (product: ProductForTitle, company: BaseCompany) =>
    product.ratePlanName ?? product.productType === ProductTypeCode.Fixed
        ? `${company.name} ${product.termMonths} Month ${product.productType}`
        : `${company.name} ${product.productType}`

export const getProductTitle = (product: ProductForTitle, company: BaseCompany, includeGreenBranding = true): string | undefined => {
    const { lineOfBusinessCode, title, isFriendsAndFamily, ratePlanName } = product
    if (ratePlanName) {
        return ratePlanName
    }
    switch (lineOfBusinessCode) {
        case LineOfBusinessCode.Electric:
        case LineOfBusinessCode.Gas:
            return getCommodityTitle(product, company, includeGreenBranding)
        case LineOfBusinessCode.HomeWarranty:
            return isFriendsAndFamily ? `Friends & Family ${title}` : title || 'Custom Product'
        default:
            throw Error(`Line of business code ${lineOfBusinessCode} is not recognized`)
    }
}

const preferredCampaigns = ['CI_BusinessPreferred']

const getShortCommodityTitle = (product: ProductForTitle): string => {
    const { termMonths, termEndMonth } = product
    const productType = getProductType(product)
    const isPreferred = preferredCampaigns.includes(product.campaignCode)

    if (termMonths) {
        return `${termMonths} Month`
    }

    if (termEndMonth) {
        const monthName = getMonthName(termEndMonth)
        const year = asDate(termEndMonth).getFullYear()
        return `${productType} Until ${monthName} ${year}`
    }

    return `${isPreferred ? 'Preferred ' : ''}${productType}`
}

export const getShortProductTitle = (product: ProductForTitle): string => {
    const { lineOfBusinessCode, title, isFriendsAndFamily } = product
    switch (lineOfBusinessCode) {
        case LineOfBusinessCode.Electric:
        case LineOfBusinessCode.Gas:
            return getShortCommodityTitle(product)
        case LineOfBusinessCode.HomeWarranty:
            return isFriendsAndFamily ? `Friends & Family ${title}` : title || 'Custom Product'
        default:
            throw Error(`Line of business code ${lineOfBusinessCode} is not recognized`)
    }
}

export const getTermLength = (termStartDate: number | undefined, termEndDate: number | undefined): number | undefined => {
    if (!termStartDate || !termEndDate) {
        return undefined
    }

    const start = new Date(termStartDate)
    const end = new Date(termEndDate)
    const monthsBetween = getDurationInMonths(start, end)
    return roundToMulitple(monthsBetween, MONTHS_PER_YEAR)
}

export const getProductTitleForAccount = (account: UserProfileAccount, company: BaseCompany): string | undefined => {
    const { lineOfBusinessCode, productTypeCode, protectionPlan, termStartDate, termEndDate, isFriendsAndFamily, ratePlanName, productCampaignCode } = account
    return getProductTitle(
        {
            lineOfBusinessCode,
            productType: productTypeCode === 'Fixed' ? ProductTypeCode.Fixed : ProductTypeCode.Variable,
            termMonths: getTermLength(termStartDate, termEndDate),
            termEndMonth: termEndDate ? getMonth(termEndDate).ordinal : undefined,
            title: (lineOfBusinessCode === LineOfBusinessCode.HomeWarranty && protectionPlan) || '',
            isFriendsAndFamily,
            ratePlanName,
            campaignCode: productCampaignCode,
        },
        company,
        true,
    )
}

export const getPriceDisplay = (
    displayPrice,
    displayPriceUnit,
    unitOfMeasure,
    discountedPriceDisplay,
    discountedPriceDisplayUnit?: PriceUnit,
    showDiscount = false,
    usePer = false,
) => {
    const hasPrice = displayPrice && displayPriceUnit && unitOfMeasure
    if (!hasPrice) {
        return ''
    }

    const displayAmount = (price, unit: PriceUnit) => {
        if (unit === PriceUnit.dollars) {
            return usePer ? `$${price} per ${unitOfMeasure}` : `$${price}/${unitOfMeasure}`
        } else if (unit === PriceUnit.cents) {
            return usePer ? `${price}¢ per ${unitOfMeasure}` : `${price}¢/${unitOfMeasure}`
        } else {
            throw new Error('Invalid Parameters: Unrecognized PriceUnit')
        }
    }

    const price = `${
        showDiscount && discountedPriceDisplay
            ? displayAmount(discountedPriceDisplay, discountedPriceDisplayUnit ?? displayPriceUnit)
            : displayAmount(displayPrice, displayPriceUnit)
    }`
    return price
}

export const getPriceWithoutUnitOfMeasure = (displayPrice, displayPriceUnit, discountedPriceDisplay, discountedPriceDisplayUnit, showDiscount = false) => {
    const displayAmount = (price, unit) => {
        if (unit === PriceUnit.dollars) {
            return `$${price}`
        } else if (unit === PriceUnit.cents) {
            return `${price}¢`
        } else {
            throw new Error('Invalid Parameters: Unrecognized PriceUnit')
        }
    }

    return `${
        showDiscount && discountedPriceDisplay
            ? displayAmount(discountedPriceDisplay, discountedPriceDisplayUnit)
            : displayAmount(displayPrice, displayPriceUnit)
    }`
}

export const getPriceWithoutUnitOfMeasureForProduct = (product: ProductModel, showDiscount = false) =>
    getPriceWithoutUnitOfMeasure(
        product.displayPrice,
        product.displayPriceUnit,
        product.discountedPriceDisplay,
        product.discountedPriceDisplayUnit,
        showDiscount,
    )

export const getPriceDisplayForProduct = (product: ProductModel, showDiscount) => {
    const { displayPrice, displayPriceUnit, unitOfMeasure, discountedPriceDisplay, discountedPriceDisplayUnit } = product
    return getPriceDisplay(displayPrice, displayPriceUnit, unitOfMeasure, discountedPriceDisplay, discountedPriceDisplayUnit, showDiscount)
}
