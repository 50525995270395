import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useEffectOnce } from 'react-use'

import { get } from 'lodash-es'

import { StateCode } from '@igs-web/common-models/constants/states'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { UtilityLineOfBusinessModel } from '@igs-web/common-models/models/utility-line-of-business-model'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { isFeatureOn } from '@igs-web/common-utilities/services/feature-flag-service'

import { FeatureFlag } from 'root/constants'

import { AlertCardWithLink } from '../../alerts/alert-card'
import { useCompany } from '../../company/hooks/useCompany'
import { AccountNumberField } from '../fields/account-number-field'

export const UtilityAccountNumberForm = ({ ulob, idPrefix, formKey = '', accountNumber = '', meterNumber = '' }: UtilityAccountNumberFormProps) => {
    const isTexasElectric = ulob.lineOfBusinessCode === LineOfBusinessCode.Electric && ulob.stateCode === StateCode.TX
    const [showUtilityChangeMessage, setShowUtilityChangeMessage] = React.useState(false)
    const { phoneNumber } = useCompany()
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext()
    const shouldCapitalizeFormField = formKey && !formKey.endsWith('.') && !formKey.endsWith(']')
    const accountNumberField = shouldCapitalizeFormField ? `${formKey}AccountNumber` : `${formKey}accountNumber`
    const accountNumberFieldError = get(errors, `${accountNumberField}.message`)
    const meterNumberField = shouldCapitalizeFormField ? `${formKey}MeterNumber` : `${formKey}meterNumber`
    const meterNumberFieldError = get(errors, `${meterNumberField}.message`)

    React.useEffect(() => {
        setValue(accountNumberField, accountNumber)
        setValue(meterNumberField, meterNumber)
    }, [setValue, accountNumber, meterNumber])

    useEffectOnce(() => {
        const getFeatureFlag = async () => {
            const isOn = await isFeatureOn(FeatureFlag.UtiltyChangeMessage)
            setShowUtilityChangeMessage(isOn)
        }
        getFeatureFlag()
    })

    return (
        <React.Fragment>
            {isTexasElectric ? (
                <Input
                    {...register(accountNumberField)}
                    dataTestId={`${idPrefix}-accountNumber`}
                    label={ulob?.accountNumberLabel ?? 'Account Number'}
                    hideHelperText={true}
                    readOnly={true}
                    defaultValue={accountNumber}
                    name={accountNumberField}
                />
            ) : (
                <AccountNumberField
                    ulob={ulob}
                    idPrefix={idPrefix}
                    accountNumber={accountNumber}
                    accountNumberFieldError={accountNumberFieldError}
                    accountNumberField={accountNumberField}
                    register={register}
                />
            )}
            {showUtilityChangeMessage && (
                <AlertCardWithLink>
                    <span>
                        Due to ongoing utility updates, your account number may have been updated since your last bill. If you are experiencing issues
                        enrolling, please call <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} /> and we would be happy to assist.
                    </span>
                </AlertCardWithLink>
            )}
            <div>
                <AccountNumberRequirementDescription rawRequirementsText={ulob.accountNumberError!} />
                {ulob.accountNumberHelpUrl && (
                    <Link href={ulob.accountNumberHelpUrl} openInNewTab={true} dataTestId="account-help-link">
                        Need help finding this on your bill?
                    </Link>
                )}
            </div>

            {ulob.meterRegex && (
                <Input
                    dataTestId={`${idPrefix}-meterNumber`}
                    {...register(meterNumberField, {
                        required: 'Required',
                        pattern: { value: new RegExp(ulob.meterRegex), message: ulob.meterErrorDisplay },
                    })}
                    label={ulob.meterNumberLabel ?? 'Meter Number'}
                    defaultValue={meterNumber}
                    error={meterNumberFieldError}
                    hideHelperText={!meterNumberFieldError}
                />
            )}
            {isTexasElectric && <div>Public Utility Commission of Texas REP Certificate number 10078 Accent Energy Texas LP dba IGS Energy</div>}
        </React.Fragment>
    )
}

interface UtilityAccountNumberFormProps {
    readonly formKey?: string
    readonly ulob: UtilityLineOfBusinessModel
    readonly idPrefix: string
    readonly accountNumber?: string
    readonly meterNumber?: string
}

export const AccountNumberRequirementDescription = ({ rawRequirementsText }: AccountNumberRequirementDescriptionProps): JSX.Element => {
    const pieces = rawRequirementsText.split('|')
    const [title, ...rules] = pieces
    return (
        <span>
            {title}
            <ul>
                {rules.map(rule => (
                    <li key={rule}>{rule}</li>
                ))}
            </ul>
        </span>
    )
}
interface AccountNumberRequirementDescriptionProps {
    readonly rawRequirementsText: string
}
