export const paymentNameMaxLength = 30
export const CHECKOUT_STORAGE_KEY = 'checkoutStateId'
export const HW_CHECKOUT_STORAGE_KEY = 'hwcheckoutStateId'
export const GAS_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY = 'gasCwmStateId'
export const HW_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY = 'hwCwmStateId'

export enum AepUlobs {
    CSP = 'CSP',
    OHP = 'OHP',
}

export enum FeatureFlag {
    HomeWarranty = 'HomeWarranty',
}

export enum KeyCodes {
    ENTER = 13,
    ESC = 27,
    ARROW_UP = 38,
    ARROW_DOWN = 40,
}

export enum HomeWarrantyBillMethod {
    UtilityBill = 'Utility Bill',
    DirectBill = 'Direct Bill',
}

export enum SessionStorageVariables {
    PasswordResetToken = 'password-reset-token',
    MessageBannerHide = 'message-banner-hide',
}

export const solarMyAccount = 'https://myaccount.igssolarpower.com/#/Login'

export enum ProductCodes {
    InsideLine = 'HP-INSIDE',
}

export const utilityBillVideoLink = 'https://www.youtube.com/watch?v=p_BsXwtTz7s'

const global = window as any

global.Igs = global.Igs || {}

const eventCodes = {
    zipCodeChanged: 'ZIP_CODE_CHANGED',
    offerRequestChanged: 'PRODUCT_REQUEST_INFO_CHANGED',
    loadingStart: 'LOADING_START',
    loadingEnd: 'LOADING_END',
    googleApiLoaded: 'GOOGLE_API_LOADED',
    userLoggedIn: 'USER_LOGGED_IN',
    userLoggedOut: 'USER_LOGGED_OUT',
}

global.Igs.EventCodes = eventCodes

export const EventCodes = eventCodes
