import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ButtonStyleType, mapToStyle } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ElementWithTooltip } from '@igs-web/common-ui-components/_atoms/tooltips/tool-tip'
import { InfoIcon } from '@igs-web/common-ui-components/_molecules/info-text'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { bodyColor, success, warning } from '@igs-web/common-ui-components/styles/theme'

import * as Routes from 'domain/routes'

import { AccountDetailsCard } from '../../account-details/account-details-card/account-details-card'
import { AccountDetailsCardHeader } from '../../account-details/account-details-card/account-details-card-header'
import { CommonReduxState } from '../../common-redux'
import { homeWarrantyPaymentProfileRedux } from '../../payment-profile/hw-payment-profile-redux'
import { PaymentPortalForm } from '../../payment/payment-portal-form'
import { ProfileSection } from '../../user-profile/user-profile-routes'
import { DashboardTileBody, HeaderLabel, ManageCell, StatusLabel } from '../billing-dashboard-tile-styles'
import { fiserveAutopayRedux } from '../fiserv-autopay-redux'

export const successStatus = { '--status-color': success } as React.CSSProperties
export const warningStatus = { '--status-color': warning } as React.CSSProperties
export const inactiveStatus = { '--status-color': bodyColor } as React.CSSProperties

const paperlessLinkText = isPaperless => (isPaperless ? 'Manage Paperless Billing' : 'Enroll in Paperless')

const enrolledText = (isInactive, isEnrolled) => {
    if (isInactive) {
        return 'Inactive'
    }
    return isEnrolled ? 'Enrolled' : 'Not Enrolled'
}
const getStyle = (isInactive, isEnrolled) => {
    if (isInactive) {
        return inactiveStatus
    }
    return isEnrolled ? successStatus : warningStatus
}

export const AccountOptionsDisplay = ({
    isPaperless,
    isAutoPay,
    hidePaperlessOptions,
    autoPayLink,
    paymentsLabel,
    paperlessLink,
    showAccountStatusTooltip = true,
    isInactive = false,
}: DisplayProps) => {
    const buttonStyleClass = mapToStyle(ButtonStyleType.Secondary)

    const autoPayText = enrolledText(isInactive, isAutoPay)
    const paperlessText = enrolledText(isInactive, isPaperless)

    return (
        <AccountDetailsCard>
            <AccountDetailsCardHeader>
                Account Options
                {showAccountStatusTooltip && (
                    <ElementWithTooltip tooltipContents="It may take up to 24 hours for your enrollment status to update.">
                        <InfoIcon />
                    </ElementWithTooltip>
                )}
            </AccountDetailsCardHeader>
            <DashboardTileBody>
                <HeaderLabel>{paymentsLabel}:</HeaderLabel>
                <StatusLabel style={getStyle(isInactive, isAutoPay)}>{autoPayText}</StatusLabel>
                <ManageCell>{autoPayLink}</ManageCell>
                {!hidePaperlessOptions && (
                    <>
                        <HeaderLabel>Paperless Documents:</HeaderLabel>
                        <StatusLabel style={getStyle(isInactive, isPaperless)}>{paperlessText}</StatusLabel>
                        <ManageCell>
                            {!isInactive && (
                                <Link className={isPaperless ? '' : `btn ${buttonStyleClass}`} to={paperlessLink}>
                                    {paperlessLinkText(isPaperless)}
                                </Link>
                            )}
                        </ManageCell>
                    </>
                )}
            </DashboardTileBody>
        </AccountDetailsCard>
    )
}

const AccountOptionsLoading = () => (
    <AccountDetailsCard>
        <AccountDetailsCardHeader>Account Options</AccountDetailsCardHeader>
        <DashboardTileBody>
            <LocalLoader isLoading={true} />
        </DashboardTileBody>
    </AccountDetailsCard>
)
// Shim to load auto pay until it comes back from userprofile,
// when it does, merge this with the display and pass from parent
export const GasAccountOptions = ({ email, zipCode, invoiceGroupId, isPaperless, hidePaperlessOptions, billingAccountNumber }: GasAccountOptionsProps) => {
    const dispatch = useDispatch()

    const fiserveAutopay = useSelector((store: CommonReduxState) => fiserveAutopayRedux.selectors.selectData(store, invoiceGroupId))
    const fiserveAutopayIsLoading = useSelector((store: CommonReduxState) => fiserveAutopayRedux.selectors.selectLoading(store, invoiceGroupId))
    const isAutoPay = !!fiserveAutopay?.isFiservAutoPayEnrolled
    React.useEffect(() => {
        const loadAutopay = () => dispatch(fiserveAutopayRedux.actions.load({ key: invoiceGroupId, ignoreCache: false, showGlobalLoader: false }))

        loadAutopay()
    }, [dispatch, invoiceGroupId])

    const autoPayLink = (
        <PaymentPortalForm
            email={email}
            zipCode={zipCode}
            submitButtonText={isAutoPay ? 'Manage Automatic Payments' : 'Enroll in AutoPay'}
            billingAccountNumber={billingAccountNumber}
            isLoggedIn={true}
            isAutoPay={true}
            buttonStyle={ButtonStyleType.Secondary}
            showAsLink={isAutoPay}
        />
    )

    if (fiserveAutopayIsLoading) {
        return <AccountOptionsLoading />
    }
    return (
        <AccountOptionsDisplay
            isPaperless={isPaperless}
            hidePaperlessOptions={hidePaperlessOptions}
            autoPayLink={autoPayLink}
            isAutoPay={!!fiserveAutopay?.isFiservAutoPayEnrolled}
            paymentsLabel="AUTOPAY"
            paperlessLink={`${Routes.AccountInformation}#${ProfileSection.CommunicationPreferences}`}
        />
    )
}

export const HwAccountOptions = ({ accountId, isPaperless, isInactive }: HwAccountOptionsProps) => {
    const buttonStyleClass = mapToStyle(ButtonStyleType.Secondary)
    const dispatch = useDispatch()
    const paymentProfile = useSelector((store: CommonReduxState) => homeWarrantyPaymentProfileRedux.selectors.selectData(store, accountId))
    const paymentProfileIsLoading = useSelector((store: CommonReduxState) => homeWarrantyPaymentProfileRedux.selectors.selectLoading(store, accountId || ''))
    const isAutoPay = paymentProfile?.paymentProfile ? true : false

    React.useEffect(() => {
        const loadAutopay = () => dispatch(homeWarrantyPaymentProfileRedux.actions.load({ key: accountId || '', showGlobalLoader: false, ignoreCache: false }))
        if (accountId) {
            loadAutopay()
        }
    }, [dispatch, accountId])
    if (paymentProfileIsLoading) {
        return <AccountOptionsLoading />
    }
    const autoPayLink = (
        <Link className={isAutoPay ? '' : `btn ${buttonStyleClass}`} to={Routes.HwManagePayment}>
            {isAutoPay ? 'Manage Recurring Payments' : 'Enroll in AutoPay'}
        </Link>
    )

    return (
        <AccountOptionsDisplay
            isAutoPay={isAutoPay}
            isPaperless={isPaperless}
            hidePaperlessOptions={false}
            paymentsLabel="Recurring payments"
            autoPayLink={isInactive ? <></> : autoPayLink}
            showAccountStatusTooltip={false}
            paperlessLink={`${Routes.HwAccountInformation}#${ProfileSection.CommunicationPreferences}`}
            isInactive={isInactive}
        />
    )
}

interface DisplayProps {
    readonly isAutoPay: boolean
    readonly autoPayLink: JSX.Element
    readonly hidePaperlessOptions: boolean
    readonly isPaperless: boolean
    readonly paymentsLabel: string
    readonly showAccountStatusTooltip?: boolean
    readonly paperlessLink: string
    readonly isInactive?: boolean
}
interface HwAccountOptionsProps {
    readonly accountId?: number
    readonly isPaperless: boolean
    readonly isInactive: boolean
}

interface GasAccountOptionsProps {
    readonly isPaperless: boolean
    readonly hidePaperlessOptions: boolean
    readonly email: string
    readonly billingAccountNumber: string
    readonly invoiceGroupId: number
    readonly zipCode?: string
}
