export const MyAccountRoute = '/MyAccount' //BF: In general, use a more specific route below, this is for path matching

//Gas Routes
export const AccountSummary = `${MyAccountRoute}/AccountSummary`
export const DashboardReload = `${AccountSummary}/Reload`
export const BillPaid = `${AccountSummary}/BillPayRepsonse`
export const AccountsAndBalances = `${AccountSummary}/AccountsAndBalances`

export const NewAccount = `${MyAccountRoute}/new-account`
export const PayBill = `${MyAccountRoute}/PayBill`
export const AccountInformation = `${MyAccountRoute}/AccountInformation`
export const RateChange = `${MyAccountRoute}/AccountUpdate`
export const RateChangeConfirmation = `${RateChange}/Confirmation`
export const Registration = `${MyAccountRoute}/Register`
export const ServiceRequest = `${MyAccountRoute}/ServiceRequests`
export const TransferService = `${MyAccountRoute}/TransferService`
export const PaymentExtension = `${MyAccountRoute}/PaymentExtension`

export const HwMyAccountRoute = `${MyAccountRoute}/HomeWarranty`

export const HwAccountSummary = `${HwMyAccountRoute}/AccountSummary`
export const HwAccountInformation = `${HwMyAccountRoute}/AccountInformation`
export const HwManagePayment = `${HwMyAccountRoute}/ManagePayment`
export const HwServiceRequests = `${HwMyAccountRoute}/ServiceRequests`
export const HwFAQs = `${HwMyAccountRoute}/FAQs`

export const HwProductSelection = `${HwMyAccountRoute}/SignUp`