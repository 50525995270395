import * as React from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { DayPicker as WfDayPicker } from '@igs/react-daypicker'
import {
    Checkbox as WfCheckbox,
    Input as WfInput,
    Radio as WfRadio,
    Select as WfSelect,
    SelectOption as WfSelectOption,
    Textarea as WfTextarea,
} from '@igs/react-styled-components'

import { FontWeight } from '../styles/font-weight'
import { darkLight, error as errorColor, fontSizeSmall } from '../styles/theme'
import { ActionLink } from './link/action-link'

const LABEL_FONT_SIZE = '1.1rem'
const INPUT_FONT_SIZE = '1.3rem'
const WF_ERROR_COLOR = errorColor
const InputStyles = css`
    input,
    select {
        padding: 7px 12px 8px 12px;
        margin-top: 13px;
    }
    label {
        top: 8px;
        color: ${darkLight};
        margin-bottom: 0.5rem;
    }
`

export const Input = styled(WfInput)`
    height: auto;
    align-self: flex-start;
    margin-bottom: ${({ hideHelperText }) => (!hideHelperText ? `-${Spacing.Medium}` : 0)};
    font-size: ${LABEL_FONT_SIZE};
    ${InputStyles}
    input {
        font-size: ${INPUT_FONT_SIZE};
        &[readonly] {
            opacity: 0.7;
            cursor: default;
            pointer-events: none;
        }
    }
    input[type='date'] {
        box-sizing: border-box;
    }
`

export const Textarea = styled(WfTextarea)`
    ${InputStyles}
`

// Remove material icons display and add an after to show an arrow down.
export const Select = styled(WfSelect)`
    ${InputStyles}
    select {
        height: auto;
        align-self: flex-start;
        font-size: ${INPUT_FONT_SIZE};
    }
    label {
        font-size: ${LABEL_FONT_SIZE};
    }

    option {
        height: auto;
        align-self: flex-start;
        font-size: ${INPUT_FONT_SIZE};
    }

    i.material-icons {
        visibility: hidden;
        width: 100%;
        &:after {
            visibility: visible;
            content: '▼';
            position: absolute;
            right: ${fontSizeSmall};
            padding-right: ${fontSizeSmall};
            top: 4px;
            font-size: ${INPUT_FONT_SIZE};
        }
    }
    > div:nth-child(even) {
        margin-bottom: -16px;
    }
`
export const SelectOption = styled(WfSelectOption)`
    height: auto;
    align-self: flex-start;
    font-size: ${INPUT_FONT_SIZE};
`

export const DayPicker = styled(WfDayPicker)`
    ${InputStyles}

    label {
        font-size: ${LABEL_FONT_SIZE};
        margin-bottom: 0;
        transform: translate(15px, 21px) scale(1);
    }

    i.material-icons {
        visibility: hidden;
        width: 100%;
        &:after {
            visibility: visible;
            content: '▼';
            position: absolute;
            right: ${fontSizeSmall};
            padding-right: ${fontSizeSmall};
            top: 4px;
            font-size: ${fontSizeSmall};
        }
    }
    .react-datepicker__navigation {
        box-sizing: border-box;
    }

    input {
        font-size: ${INPUT_FONT_SIZE};
        padding: 14px 12px;
    }
`

export const Checkbox = styled(WfCheckbox)<CheckboxProps>`
    & ~ span {
        font-size: ${INPUT_FONT_SIZE};
        font-weight: ${FontWeight.Medium};
    }
    & ~ div {
        height: ${Spacing.Medium};
        width: ${Spacing.Medium};
        box-sizing: content-box;
        ${({ $error }) => ($error ? `border-color: ${WF_ERROR_COLOR};` : '')}
    }
    & ~ label {
        margin-bottom: 0;
        align-items: start;
    }
`
interface CheckboxProps {
    readonly $error?: boolean
}

export const LegalCheckBox = styled(Checkbox)`
    & ~ span {
        font-size: 1rem;
        font-weight: ${FontWeight.Default};
    }
`

export const Radio = styled(WfRadio)`
    box-sizing: content-box;
    ~ p {
        margin-bottom: 0;
        margin-top: 0;
        font-size: ${INPUT_FONT_SIZE};
    }
    ~ div {
        box-sizing: content-box;
    }
`
const StyledActionLink = styled(ActionLink)`
    position: absolute;
    top: 1.2rem;
    right: 2.5rem;
    font-size: ${fontSizeSmall};
    z-index: 10;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }
`

const PasswordInputWrapper = styled.div`
    position: relative;
`
interface PasswordFieldProps extends UseFormRegisterReturn {
    readonly error: string | undefined
    readonly label: string
    readonly dataTestId: string
}

export const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>(
    ({ error, label, dataTestId, onChange, onBlur, name }: PasswordFieldProps, ref) => {
        const [showText, setShowText] = React.useState(false)
        const toggleShowText = (): void => {
            setShowText(!showText)
        }

        return (
            <PasswordInputWrapper>
                <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    name={name}
                    dataTestId={dataTestId}
                    type={showText ? 'text' : 'password'}
                    defaultValue=""
                    label={label}
                    error={error}
                    hideHelperText={!error}
                    autoComplete="new-password"
                />
                <StyledActionLink className="input-action" onClick={toggleShowText} tabIndex={-1}>
                    {showText ? 'Hide' : 'Show'}
                </StyledActionLink>
            </PasswordInputWrapper>
        )
    },
)
PasswordField.displayName = 'PasswordField'

//colors and stled adapted from mui inputs to look similar
export const InlineInput = styled.input`
    padding: 7px 12px 8px 12px;
    border: 1px solid #ccd1d4;
    border-radius: 4px;
    outline: none;
    font-size: 1.3rem;
    &:hover:not(:disabled):not(:focus) {
        border-color: #66747d;
    }
    &:focus {
        border: 2px solid #005cb9;
    }
`
