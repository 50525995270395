import { Address } from '@igs-web/common-models/models/address'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { CreditCheckRequest } from '@igs-web/common-models/models/credit-check-request'
import { DiscountRequest, DiscountResponse } from '@igs-web/common-models/models/discount-dto'
import { EmailMeRatesRequest } from '@igs-web/common-models/models/email-me-rates-request'
import { EnrollmentResponse } from '@igs-web/common-models/models/enrollment-response'
import { IndustryType } from '@igs-web/common-models/models/industry-type'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { MoveInDateResponse } from '@igs-web/common-models/models/move-in-date-response'
import { OfferAndUtilityResponse, OfferRequest } from '@igs-web/common-models/models/offer-and-utility-dto'
import { PremiseResponse } from '@igs-web/common-models/models/premise-response'
import { RequestConfig, apiClient } from '@igs-web/common-utilities/api/api-client'
import { cache } from '@igs-web/common-utilities/api/cache'
import { buildQueryStringUrl } from '@igs-web/common-utilities/utilities/query-string-utilities'

class EnrollmentApiClient {
    public readonly getOffers = (request: OfferRequest, ignoreCache = false, config?: RequestConfig) => {
        const cacheKey = 'getOffers'
        const url = buildQueryStringUrl(request, `${apiClient.apiBaseUrl}/OffersAndUlobs`, true)
        return apiClient.cachedGet<OfferAndUtilityResponse>(url, cacheKey, ignoreCache, config)
    }

    public readonly getHwOffers = (zipCode: string, campaignCode: string, ignoreCache = false, config?: RequestConfig) => {
        const cacheKey = 'getOffers'
        const request = { zipCode, campaignCode }
        const url = buildQueryStringUrl(request, `${apiClient.apiBaseUrl}/HwOffersAndUlobs`, true)
        return apiClient.cachedGet<OfferAndUtilityResponse>(url, cacheKey, ignoreCache, config)
    }

    public readonly getRenewalOffers = (zipCode: string, partyAccountId: number, ignoreCache = false, config?: RequestConfig) => {
        const cacheKey = 'getRenewalOffers'
        const request = {
            zipCode,
            partyAccountId,
        }
        const url = buildQueryStringUrl(request, `${apiClient.apiBaseUrl}/GetRenewalOffers`)
        return apiClient.cachedGet<OfferAndUtilityResponse>(url, cacheKey, ignoreCache, config)
    }

    public readonly getDiscounts = cache(
        'getDiscounts',
        async (request: DiscountRequest): Promise<ReadonlyArray<DiscountResponse> | null> => {
            return await apiClient.post<ReadonlyArray<DiscountResponse>>(`${apiClient.apiBaseUrl}/discounts`, request)
        },
    )

    public readonly getIndustryTypes = cache('getIndustryTypes', async () => {
        return await apiClient.get<ReadonlyArray<IndustryType>>(`${apiClient.apiBaseUrl}/IndustryTypes`)
    })

    public readonly getMoveInDateEligibility = cache(
        'getMoveInDateEligibility',
        async (utilityCode: string, lineOfBusinessCode: LineOfBusinessCode, date: Date) => {
            return await apiClient.post<MoveInDateResponse>(`${apiClient.apiBaseUrl}/MoveInDateEligibility`, {
                utilityCode,
                serviceTypeCode: lineOfBusinessCode === LineOfBusinessCode.Gas ? 'GAS' : 'EL',
                moveInDate: date,
            })
        },
    )

    public readonly getValidMoveInDates = cache('getValidMoveInDates', async (utilityCode: string, lineOfBusinessCode: LineOfBusinessCode) => {
        return await apiClient.post<ReadonlyArray<Date>>(`${apiClient.apiBaseUrl}/ValidMoveInDates`, {
            utilityCode,
            serviceTypeCode: lineOfBusinessCode === LineOfBusinessCode.Gas ? 'GAS' : 'EL',
        })
    })

    public readonly serviceAddressLookup = cache(
        'searchTxPremise',
        async (utilityCode: string | undefined, address?: PremiseSearchAddressRequest, accountNumber?: string) => {
            if (!address && !accountNumber) {
                return []
            }

            const response = await apiClient.post<{ readonly premises: ReadonlyArray<PremiseResponse> }>(`${apiClient.apiBaseUrl}/premise/search`, {
                AccountNumber: accountNumber,
                UtilityCode: utilityCode,
                Address1: address && address.address1,
                Address2: address && address.address2,
                City: address && address.city,
                ZipCode: address && address.zipCode,
            })

            if (response) {
                return response.premises
            } else {
                return []
            }
        },
    )

    public readonly submitEnrollment = async (request: EnrollmentRequest) => {
        return await apiClient.post<EnrollmentResponse>(`${apiClient.apiBaseUrl}/enroll`, request)
    }

    public readonly getTermsAndConditionsUrl = (
        productCode: string,
        accountType: string,
        zipCode?: string,
        offerCode?: string | null,
        campaignCode?: string | null,
    ) => {
        if (zipCode) {
            return buildQueryStringUrl(
                {
                    productCode,
                    locationType: accountType,
                    zipCode,
                    offerCode,
                    campaignCode,
                },
                `${apiClient.apiBaseUrl}/TermsAndConditions`,
            )
        }
        return undefined
    }

    public readonly getFactsLabelUrl = (productCode: string) => {
        return buildQueryStringUrl({ productCode }, `${apiClient.apiBaseUrl}/FactsLabel`)
    }

    public readonly getIllinoisUniformDisclosureUrl = (productCode: string, customerType: string, zipCode: string) => {
        return buildQueryStringUrl({ productCode, customerType, zipCode }, `${apiClient.apiBaseUrl}/IllinoisUniformDisclosureStatement`)
    }

    public readonly getLetterOfAgency = (productCode: string, customerType: string, zipCode: string) => {
        return buildQueryStringUrl({ productCode, customerType, zipCode }, `${apiClient.apiBaseUrl}/LetterOfAgency`)
    }

    public readonly getCustomerRightsUrl = (stateCode: string) => {
        return buildQueryStringUrl({ stateCode }, `${apiClient.apiBaseUrl}/CustomerRights`)
    }

    public readonly triggerRatesEmail = async (request: EmailMeRatesRequest) => {
        return await apiClient.post<void>(`${apiClient.apiBaseUrl}/email/rates`, request)
    }

    public readonly checkCredit = async (request: CreditCheckRequest) => {
        return await apiClient.post<CreditCheckResponse>(`${apiClient.apiBaseUrl}/CreditCheck`, request)
    }
}

export const enrollmentApiClient = new EnrollmentApiClient()

export interface EnrollmentRequest {
    readonly campaignCode: string
    readonly shoppingCartItems: ReadonlyArray<EnrollmentShoppingCartItemRequest>
    readonly billingAddress: EnrollmentAddressRequest
    readonly serviceAddress: EnrollmentAddressRequest
    readonly shippingAddress: EnrollmentAddressRequest
    readonly defaultContact: EnrollmentContactRequest
    readonly accountType: string | null | undefined
    readonly orderId: number | undefined
    readonly invitationCode?: string | null | undefined
    readonly agentCode?: string | null | undefined
    readonly referralId?: string | null | undefined
    readonly depositAmount?: number | null | undefined
    readonly depositPayment: EnrollmentPaymentRequest | null | undefined
    readonly isPaperless: boolean
    readonly hasElectronicCommunicationConsent?: boolean
    readonly autopayPayment: EnrollmentPaymentRequest | null | undefined
    readonly proofOfExemption?: boolean | null | undefined
    readonly commercialDeposit?: CommercialDepositModel | null | undefined
    readonly moveInDate?: Date | null | undefined
    readonly paperlessWelcomeDocuments?: boolean
    readonly confirmStartDateTerms?: boolean
    readonly technicianPermission?: boolean
    readonly realtorId?: string
    readonly realtorName?: string
    readonly deviceId?: string
    readonly ipAddress?: string
    readonly electronicAgreementNumber?: string | null
}

export interface EnrollmentShoppingCartItemRequest {
    readonly contact: EnrollmentContactRequest
    readonly accountNumber: string | null | undefined
    readonly accountId: number | null | undefined
    readonly partyId: number | null | undefined
    readonly orderItemId: number | null | undefined
    readonly shoppingCartItemKey: string
    readonly payment: EnrollmentPaymentRequest | null | undefined
    readonly meterNumber: string | null | undefined
    readonly offerId: number
    readonly priceEffectiveDate?: Date
    readonly products: ReadonlyArray<EnrollmentProductRequest>
    readonly deferEnrollmentUnitilEndOfCurrentContractTerm?: boolean
}

export interface EnrollmentPaymentRequest {
    readonly paymentType?: string
    readonly cardType?: string
    readonly name?: string
    readonly cardNumber?: string
    readonly expirationYear?: string
    readonly expirationMonth?: string
    readonly securityCode?: string
    readonly zipCode?: string
    readonly bankAccountNumber?: string
    readonly bankRoutingNumber?: string
    readonly billingUtilityCode?: string
    readonly billingLineOfBusinessCode?: string
    readonly billingUtilityAccountNumber?: string
}

export interface EnrollmentContactRequest {
    readonly firstName: string | null | undefined
    readonly lastName: string | null | undefined
    readonly middleInitial: string | null | undefined
    readonly phoneNumber: string | null | undefined
    readonly isMobilePhone: boolean | null | undefined
    readonly isMobilePhoneMarketable: boolean | null | undefined
    readonly emailAddress: string | null | undefined
    readonly commercialName: string | null | undefined
    readonly isEmailMarketable: boolean | null | undefined
}

export interface EnrollmentAddressRequest {
    readonly address1: string | null | undefined
    readonly address2: string | null | undefined
    readonly city: string | null | undefined
    readonly state: string | null | undefined
    readonly zipCode: string | null | undefined
    readonly hasCentralAc: boolean | null | undefined
}

export interface EnrollmentProductRequest {
    readonly lineOfBusinessCode: string | null | undefined
    readonly productCode: string | null | undefined
    readonly utilityCode: string | null | undefined
    readonly hpOfferCode: string | null | undefined
    readonly territoryCode: string | null | undefined
    readonly discountedPrice: number | null | undefined
    readonly discountId: number | null | undefined
    readonly basePrice: number | null | undefined
    readonly unitOfMeasure: string | null | undefined
    readonly ratePlanName: string | null | undefined
    readonly monthlyCharge: number | null | undefined
    readonly overrideCampaignCode: string | null | undefined
}

interface CommercialDepositModel {
    readonly industryTypeId?: number | null | undefined
    readonly isNewBusiness?: boolean | null | undefined
    readonly squareFootage?: number | null | undefined
    readonly industryTypeDescription?: string | null | undefined
}

export interface PremiseSearchAddressRequest {
    readonly address1?: string
    readonly address2?: string
    readonly city?: string
    readonly zipCode?: string
}

export enum CreditCheckStatus {
    Waive = 'WAIVE',
    Collect = 'COLLECT',
    Deny = 'DENY',
    DenyDueToCustomerBalance = 'DENYDUETOCUSTOMERBALANCE',
}
export enum CreditCheckResultCode {
    Hold = 'HOLD',
    NoHit = 'NOHIT',
    ItpaHold = 'ITPAHOLD',
}

export enum BrandCode {
    SCANA = 'SCANA',
    SCANAREG = 'SCANAREG',
}

export interface CreditCheckCustomerBalance {
    readonly balance: number
    readonly brandCode: BrandCode
    readonly customerName: string
    readonly message: string
    readonly pastDueBalanceLessUnappliedCredits: number
    readonly referenceNumber: string
    readonly writeOffAmount: number
}

export interface CreditCheckResponse {
    readonly depositAmount: number
    readonly energyScoreStatus?: CreditCheckStatus
    readonly resultCode: CreditCheckResultCode
    readonly partyId: number
    readonly serviceAddress?: Address
    readonly contact?: ContactModel
    readonly customerBalances: ReadonlyArray<CreditCheckCustomerBalance>
}
