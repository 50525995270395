import { ChartData, ChartOptions } from 'chart.js'
import { draw } from 'patternomaly'
import * as React from 'react'

import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import Bar from './vendor/bar'

export const patterns = {
    Diagonal: (color: string) => draw('diagonal-right-left', color),
    ZigZag: (color: string) => draw('zigzag', color),
}

const standardChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        position: 'bottom',
        labels: {
            fontSize: 20,
            fontStyle: 'bold',
            padding: 20,
            usePointStyle: false,
        },
    },
}

const axisOptions = (xLabel: string, yLabel: string): ChartOptions => ({
    scales: {
        yAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: yLabel,
                    fontStyle: 'bold',
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                gridLines: {
                    display: true,
                },
                scaleLabel: {
                    display: true,
                    labelString: xLabel,
                    fontStyle: 'bold',
                },
            },
        ],
    },
})

const getChartOptions = (xLabel: string, yLabel: string, options?: ChartOptions): ChartOptions => ({
    ...standardChartOptions,
    ...options,
    ...axisOptions(xLabel, yLabel),
})

export const BarChart = ({ xLabel, yLabel, data, options }: Props): JSX.Element => (
    <React.Suspense fallback={<LocalLoader isLoading={true} />}>
        <Bar data={data} options={getChartOptions(xLabel, yLabel, options)} height={1} width={4} />
    </React.Suspense>
)

interface Props {
    readonly xLabel: string
    readonly yLabel: string
    readonly data: ChartData
    readonly options?: ChartOptions
}
