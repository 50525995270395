import * as React from 'react'

import styled from 'styled-components'

import { CloseButton } from '@igs-web/common-ui-components/_atoms/buttons/close-button'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import {
    fontSizeMedium,
    marketingBannerBackground,
    marketingBannerForeground,
    marketingBannerLinkDecoration,
    marketingBannerLinkForeground,
} from '@igs-web/common-ui-components/styles/theme'
import { SessionStorageVariables } from '@igs-web/common-utilities/constants/constants'
import { getFromStorage, putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

const Banner = styled.div<BannerComponentProps>`
    display: ${({ endDate }) => (endDate && new Date(endDate) < new Date() ? 'none' : 'grid')};
    place-items: center;
    ${({ isDismissable }) => (isDismissable ? 'grid-template-columns: 1fr min-content;' : 'grid-template-columns: 1fr;')}

    > button {
        grid-column: 2;
        &::before,
        &::after {
            top: unset;
        }
        padding-left: 1rm;
    }
    width: 100%;
    padding-inline-start: ${Spacing.Large};
    padding-inline-end: ${({ isDismissable }) => (isDismissable ? Spacing.Medium : Spacing.Large)};
    padding-block: ${Spacing.Large};
    background-color: ${marketingBannerBackground};
    color: ${marketingBannerForeground};
    ${({ isBold }) =>
        isBold &&
        `font-weight: ${FontWeight.Bold};
        font-size: ${fontSizeMedium};`}
    p:last-of-type {
        margin-block: 0;
    }
    a {
        color: ${marketingBannerLinkForeground};
        text-decoration: ${marketingBannerLinkDecoration};
    }
`
interface BannerComponentProps {
    readonly isDismissable: boolean
    readonly isBold: boolean
    readonly endDate?: Date
}

export const MessageBannerDisplay = ({ children, isDismissable, handleHide, className, endDate, isBold = true, dataTestId }: BannerDisplayProps) => (
    <Banner className={className} isDismissable={isDismissable} isBold={isBold} endDate={endDate} data-testid={dataTestId}>
        <div>{children}</div>
        {isDismissable && <CloseButton $color="black" onClick={handleHide} />}
    </Banner>
)

interface BannerDisplayProps extends BannerProps {
    readonly handleHide: () => void
    readonly className?: string
}

const getHideBanner = (id: string): boolean => {
    const hiddenBanners = getFromStorage<ReadonlyArray<string>>(SessionStorageVariables.MessageBannerHide) || []
    return hiddenBanners.includes(id)
}
const setHideBanner = (id: string) => {
    const hiddenBanners = getFromStorage<ReadonlyArray<string>>(SessionStorageVariables.MessageBannerHide) || []
    putInStorage<ReadonlyArray<string>>([...hiddenBanners, id], SessionStorageVariables.MessageBannerHide)
}

export const MessageBanner = (props: BannerProps) => {
    const { isDismissable, id, children } = props
    const bannerHidden = isDismissable ? getHideBanner(id) : false
    const [isHidden, setIsHidden] = React.useState(bannerHidden)

    const hideBanner = () => {
        setIsHidden(true)
        setHideBanner(id)
    }
    return isHidden ? (
        <></>
    ) : (
        <MessageBannerDisplay {...props} handleHide={hideBanner}>
            {children}
        </MessageBannerDisplay>
    )
}

export const SFMessageBanner = ({ message, ...rest }: SFBannerProps) => <MessageBanner {...rest}>{message}</MessageBanner>

const StyledMessageBanner = styled(MessageBanner)<StyledMessageBannerProps>`
    box-shadow: 0 0 0 100vmax ${({ solidBackgroundColor }) => solidBackgroundColor};
    clip-path: inset(0 -100vmax);
    background: ${({ solidBackgroundColor }) => solidBackgroundColor};
`

interface StyledMessageBannerProps {
    readonly solidBackgroundColor: string
}
export const FullWidthMessageBanner = ({ solidBackgroundColor = marketingBannerBackground, children, ...props }: FullWidthBannerProps) => {
    return (
        <StyledMessageBanner solidBackgroundColor={solidBackgroundColor} {...props}>
            {children}
        </StyledMessageBanner>
    )
}

interface BannerProps {
    readonly id: string
    readonly isDismissable: boolean
    readonly className?: string
    readonly children?: React.ReactNode
    readonly isBold?: boolean
    readonly endDate?: Date
    readonly dataTestId: string
}

interface SFBannerProps extends BannerProps {
    readonly message: string
    readonly endDate: Date
}
interface FullWidthBannerProps extends BannerProps {
    readonly solidBackgroundColor?: string
}
