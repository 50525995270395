import * as React from 'react'
import { FieldError } from 'react-hook-form'
import styled from 'styled-components'

import { error } from '@igs-web/common-ui-components/styles/theme'

const ErrorMessage = styled.p`
    color: ${error};
`

export const ErrorField = ({ showErrorMessage, errorMessage }: Props) => {
    return <>{showErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}</>
}

interface Props {
    readonly showErrorMessage?: boolean | FieldError
    readonly errorMessage: string
}
