import * as React from 'react'
import { FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'

import { SubmitButton } from '@igs-web/common-components/_molecules/final-form/submit-button'
import { BoxedErrorAlert, BoxedSuccessAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { HtmlForm } from '@igs-web/common-ui-components/_atoms/forms/html-form'
import { TypedForm } from '@igs-web/common-ui-components/_atoms/forms/typed-form'
import { PasswordField } from '@igs-web/common-ui-components/_molecules/form-inputs/password-field'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { formError } from '@igs-web/common-utilities/services/form-service'
import { composeValidators, mustMatch, passwordLength, required } from '@igs-web/common-utilities/utilities/form-validation'
import { clearFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { SessionStorageVariables } from 'root/constants'
import { urlHelper } from 'root/shared-services/url-helper'

import { useCompany } from '../company/hooks/useCompany'

const clearPasswordResetKey = (): void => clearFromStorage(SessionStorageVariables.PasswordResetToken)

export const ResetPasswordForm = ({ uniqueId, passwordResetKey, onError }: Props) => {
    const history = useHistory()
    const company = useCompany()

    const [userEmail, setUserEmail] = React.useState<string | undefined>(undefined)
    const showErrors = !onError

    React.useEffect(() => {
        getUserEmailFromPasswordResetKey(passwordResetKey)
    }, [passwordResetKey])

    const getUserEmailFromPasswordResetKey = async (key: string) => {
        if (key) {
            const response = await customerInfoApiClient.getUserNameFromResetKey(key)
            if (response && response.data) {
                setUserEmail(response.data)
            }
        }
    }

    const submitForm = async (submission: Fields) => {
        try {
            await customerInfoApiClient.updatePasswordFromReset(submission.newPassword, submission.passwordResetKey, {
                showGlobalLoader: true,
                overrideNonUserPresentableErrors: 'Failed to set Password',
            })

            clearPasswordResetKey()
            if (company.features.preferRoutingOverHref) {
                const loginUrl = urlHelper.login(false, userEmail, true)
                const loginUrlAsString = loginUrl.asString()
                history.push(loginUrlAsString)
            } else {
                urlHelper.login(false, userEmail, true).go()
            }
        } catch (e) {
            console.log(e)
            if (onError) {
                onError(e.message)
            }
            return formError(e.message)
        }
    }

    return (
        <TypedForm<Fields> initialValues={{ passwordResetKey }} onSubmit={submitForm}>
            {({ handleSubmit, submitSucceeded, submitError }: FormRenderProps) => (
                <HtmlForm onSubmit={handleSubmit}>
                    <BoxedSuccessAlert show={submitSucceeded} message="Password Reset Successfully" />
                    {showErrors && <BoxedErrorAlert message={submitError} />}

                    <PasswordField<Fields>
                        name="newPassword"
                        id={`reset-password-${uniqueId}`}
                        dataTestId={`reset-password-${uniqueId}`}
                        validate={composeValidators(required('Password is required'), passwordLength)}
                        label="New Password"
                    />

                    <PasswordField
                        name="newPasswordConfirm"
                        id={`reset-password-confirm-${uniqueId}`}
                        dataTestId={`reset-password-confirm-${uniqueId}`}
                        validate={composeValidators(required('New Password Confirmation is required'), mustMatch('newPassword', 'Passwords must match'))}
                        label="Confirm New Password"
                    />

                    <SubmitButton id={`reset-password-submit-${uniqueId}`} dataTestId={`reset-password-submit-${uniqueId}`} className="reset-password-btn">
                        Submit
                    </SubmitButton>
                </HtmlForm>
            )}
        </TypedForm>
    )
}

interface Fields {
    readonly passwordResetKey: string
    readonly newPassword: string
}

interface Props {
    readonly uniqueId: string
    readonly passwordResetKey: string
    readonly onError?: (error: string) => void
}
