import { NavigationBanner } from 'molecules/header/navigation-banner'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { BillingAccountSelectForm } from '@igs-web/common-components/domain/myaccount/billing-accounts/billingaccount-select-form'
import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { userActions, UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { UserProfileBillingAccount } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { BillingAccountTransactionHistory } from '@igs-web/common-models/models/billing-account-transaction-history'
import { ContainerGrid } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeLarge } from '@igs-web/common-ui-components/styles/theme'
import { CreatePaymentExtensionRequest, paymentApiClient } from '@igs-web/common-utilities/api/payment-api-client'
import { getSortedBillingAccountItems } from '@igs-web/common-utilities/utilities/billing-account-utilities'
import { getUrlParameter } from '@igs-web/common-utilities/utilities/query-string-utilities'

import { CreatePaymentExtension } from './create-payment-extension'
import { ExistingPaymentExtension } from './existing-payment-extension'
import { IneligibleForPaymentExtension } from './ineligible-for-payment-extension'
import { PaymentExtensionError } from './payment-extension-error'

const PaymentExtensionPageStyles = styled(ContainerGrid)`
    margin-top: ${Spacing.ExtraLarge};
    margin-bottom: ${Spacing.ExtraLarge};
    text-align: center;
    grid-row-gap: ${Spacing.ExtraLarge};
    justify-items: center;
    @media (max-width: ${Breakpoint.Tablet}) {
        padding: 0 1%;
    }
    p {
        font-size: ${fontSizeLarge};
        line-height: 1.5;
        margin: 0;
    }
`
const BillingAccountSelectorWrapper = styled.p`
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;
    grid-row-gap: ${Spacing.Small};

    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
    }
`
export enum PaymentExtensionPageState {
    create = 'create',
    ineligible = 'ineligible',
    existing = 'existing',
    error = 'error',
}

const determineInitialState = (billingAccount: UserProfileBillingAccount | undefined): PaymentExtensionPageState => {
    if (!billingAccount) {
        return PaymentExtensionPageState.ineligible
    }
    if (billingAccount?.paymentExtension.exisitingPaymentExtensionDueDate) {
        return PaymentExtensionPageState.existing
    }
    if (billingAccount?.paymentExtension.isEligibleForPaymentExtension === false) {
        return PaymentExtensionPageState.ineligible
    }
    return PaymentExtensionPageState.create
}

export const PaymentExtensionPage = () => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))
    const dispatch = useDispatch()
    const urlBillingAccountNumber = getUrlParameter('billingAccountNumber')
    const [billingAccount, setBillingAccount] = React.useState<UserProfileBillingAccount | undefined>(
        userProfile?.billingAccounts.length === 1
            ? userProfile?.billingAccounts[0]
            : userProfile?.billingAccounts.find(b => b.billingAccountNumber === urlBillingAccountNumber),
    )
    const [isNewExtension, setIsNewExtension] = React.useState(false)
    const loadUserProfile = () => dispatch(userActions.loadUser())
    const [transactionHistory, setTransactionHistory] = React.useState<BillingAccountTransactionHistory | null>()
    const { profile, getTransactionHistory, getBillingAccountStatus } = useMyAccountDashboard()
    const isActive = billingAccount && getBillingAccountStatus(billingAccount.billingAccountNumber) === AccountStatus.active
    const hasPaymentExtension = !!billingAccount?.paymentExtension.exisitingPaymentExtensionDueDate
    const billingAccountsList = getSortedBillingAccountItems(profile.billingAccounts, getBillingAccountStatus)
    const [paymentExtensionPageState, setPaymentExtensionPageState] = React.useState(determineInitialState(billingAccount))
    const selectAccount = selectedBillingAccountNumber => {
        setBillingAccount(userProfile?.billingAccounts.find(b => b.billingAccountNumber === selectedBillingAccountNumber))
    }

    React.useEffect(() => {
        setBillingAccount(userProfile?.billingAccounts.find(b => b.billingAccountNumber === billingAccount?.billingAccountNumber))
    }, [userProfile])

    React.useEffect(() => {
        if (isActive) {
            if (!hasPaymentExtension) {
                setTransactionHistory(null)
                getTransactionHistory(billingAccount?.billingAccountNumber).then(result => {
                    setTransactionHistory(result)
                })
            }
        }
        setPaymentExtensionPageState(determineInitialState(billingAccount))
    }, [billingAccount, isActive])

    const handleSubmit = async () => {
        try {
            const request = {
                billingAccountNumber: billingAccount?.billingAccountNumber,
                proposedPaymentExtensionDueDate: billingAccount?.paymentExtension.proposedPaymentExtensionDueDate,
                customerName: `${profile.firstName} ${profile.lastName}`,
            } as CreatePaymentExtensionRequest

            await paymentApiClient.createPaymentExtension(request)
            await loadUserProfile()
            setIsNewExtension(true)
            setPaymentExtensionPageState(PaymentExtensionPageState.existing)
        } catch {
            setPaymentExtensionPageState(PaymentExtensionPageState.error)
        }
    }

    return (
        <>
            <NavigationBanner title="Payment Extension" />

            <PaymentExtensionPageStyles maxWidth={Breakpoint.Desktop}>
                {billingAccountsList.length === 1 && billingAccount && (
                    <p>
                        <strong>Billing Account Number:</strong>
                        <br />
                        <strong> {billingAccount?.billingAccountNumber}</strong>
                    </p>
                )}
                {billingAccountsList.length > 1 && (
                    <BillingAccountSelectorWrapper>
                        <strong>Billing Account Number:</strong>{' '}
                        <BillingAccountSelectForm
                            billingAccounts={billingAccountsList}
                            onSelect={selectAccount}
                            selectedBillingAccount={billingAccount?.billingAccountNumber}
                        />
                    </BillingAccountSelectorWrapper>
                )}

                {PaymentExtensionPageState.create === paymentExtensionPageState && (
                    <CreatePaymentExtension
                        amountDue={billingAccount?.amountDue}
                        pastDueBalance={transactionHistory?.pastDueBalance}
                        billingAccountNumber={billingAccount?.billingAccountNumber}
                        handleSubmit={handleSubmit}
                        orginalDueDate={billingAccount?.dueDate}
                        paymentExtension={billingAccount?.paymentExtension}
                    />
                )}

                {PaymentExtensionPageState.ineligible === paymentExtensionPageState && (
                    <IneligibleForPaymentExtension
                        billingAccountNumber={billingAccount?.billingAccountNumber}
                        billingAccountCount={billingAccountsList.length}
                    />
                )}

                {PaymentExtensionPageState.existing === paymentExtensionPageState && (
                    <ExistingPaymentExtension
                        orginalDueDate={billingAccount?.dueDate}
                        billingAccountNumber={billingAccount?.billingAccountNumber}
                        amountDue={billingAccount?.amountDue}
                        paymentExtension={billingAccount?.paymentExtension}
                        isNewExtension={isNewExtension}
                    />
                )}

                {PaymentExtensionPageState.error === paymentExtensionPageState && (
                    <PaymentExtensionError billingAccountNumber={billingAccount?.billingAccountNumber} />
                )}
            </PaymentExtensionPageStyles>
        </>
    )
}

export interface PaymentExtensionProps {
    readonly billingAccountNumber?: string
    readonly amountDue?: number
}
