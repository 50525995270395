import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lgWidth, primaryDark } from '@igs-web/common-ui-components/styles/theme'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { MasterGrid } from '@igs-web/common-ui-components/_molecules/grid/master-grid'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import * as React from 'react'
import styled from 'styled-components'

const StyledFullPageErrorContainer = styled(MasterGrid)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30000;
    background: ${primaryDark};
    padding: ${Spacing.XxLarge};
`

const ScrollableStyledFullPageErrorContainer = styled(StyledFullPageErrorContainer)`
    overflow-y: scroll;
    /*Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`

const ErrorContentContainer = styled.div`
    display: grid;
    grid-gap: ${Spacing.Medium};
    color: white;
    grid-auto-rows: min-content;
    place-self: center;
    max-width: ${lgWidth};
    ${Header4} {
        color: white;
    }
`

export const FullPageError = ({ children, header }: Props) => (
    <StyledFullPageErrorContainer>
        <img src={apiClient.getAssetPath('lightbulb-tools.png')} />
        <ErrorContentContainer>
            {header && <Header4>{header}</Header4>}
            {children}
        </ErrorContentContainer>
    </StyledFullPageErrorContainer>
)

export const ScrollableFullPageError = ({ children, header }: Props) => (
    <ScrollableStyledFullPageErrorContainer>
        <img src={apiClient.getAssetPath('lightbulb-tools.png')} />
        <ErrorContentContainer>
            {header && <Header4>{header}</Header4>}
            {children}
        </ErrorContentContainer>
    </ScrollableStyledFullPageErrorContainer>
)

interface Props {
    readonly children: React.ReactNode
    readonly header?: string
}
