import * as React from 'react'

import { ThemeProvider } from 'styled-components'

import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { defaultTheme } from '@igs-web/common-ui-components/styles/theming/default-theme'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import { useCompany } from '../company/hooks/useCompany'

declare global {
    interface Window {
        readonly genesysWebchat: {
            startCobrowse: any
        }
    }
}

export const ScreenSharePage = (): JSX.Element => {
    const company = useCompany()
    const screenShareName = `${company.displayName} Screen Share`
    React.useEffect(() => {
        window.genesysWebchat.startCobrowse()
    }, [])
    return (
        <>
            <NavigationBanner title={screenShareName} />
            <ThemeProvider theme={defaultTheme}>
                <PageContentContainer>
                    <GridSpacer spacing={Spacing.Large} />
                    <Header3>Thank you for accessing {screenShareName}.</Header3>
                    <p>Please enter your code below to share your screen with the agent.</p>
                    <div id="cobrowse-security-form" />
                    <p>
                        By entering the Security Key, you accept and agree to be bound and abide by these{' '}
                        <ExternalLink href="https://cdn.igs.com/cdn/web/ScreenShare/screenshare-terms-of-use.pdf">Screen Share Terms of Use</ExternalLink>
                    </p>
                </PageContentContainer>
            </ThemeProvider>
        </>
    )
}
