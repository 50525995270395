/* eslint-disable complexity */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { AccountTypeName } from '@igs-web/common-models/constants/account-type'
import { ProductTypeDescription, UserProfileServiceAddress } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { BoxContainer } from '@igs-web/common-ui-components/_atoms/box-container'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightLight } from '@igs-web/common-ui-components/styles/theme'
import { canRenew } from '@igs-web/common-utilities/services/contract-eligibility-service'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { getProductTitleForAccount } from '@igs-web/common-utilities/utilities/offer-utilities'

import { InelligibleRateChangeMessage } from 'domain/rate-change/inelligible-rate-change-message'
import * as Routes from 'domain/routes'

import { CommonReduxState } from '../common-redux'
import { useCompany } from '../company/hooks/useCompany'
import { MyAccountSelectors, myAccountActions } from '../myaccount-redux'
import { ServiceAddressForm } from '../service-address/service-address-form'
import { UserSelectors } from '../user/user-redux'

const RatePlanPrefContainer = styled(BoxContainer)`
    display: grid;
    grid-row-gap: ${Spacing.Large};
    grid-template-areas:
        'selector selector selector'
        'title body cta'
        'footer footer footer';
    grid-row-gap: ${Spacing.Large};
    footer {
        grid-area: footer;
    }
    @media (max-width: ${Breakpoint.Desktop}) {
        display: flex;
        flex-direction: column;
    }
`

const StyledAddressSelector = styled.div`
    grid-area: selector;
    & > * {
        max-width: 100%;
    }
`
const TitleContainer = styled.div`
    font-weight: ${FontWeight.Bold};
    grid-area: title;
`
const BodyContainer = styled.div`
    grid-area: body;
`

const CTAContainer = styled.div`
    grid-area: cta;
    text-align: center;
    place-self: center end;
`

export const RatePlanPreferencesSection = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const selectServiceAddress = (serviceAddressKey: string) => dispatch(myAccountActions.selectServiceAddress(serviceAddressKey))
    const serviceAddresses = useSelector(UserSelectors.selectUserServiceAddresses)
    const selectedServiceAddress = useSelector(MyAccountSelectors.selectSelectedServiceAddress)

    const accounts = useSelector((state: CommonReduxState) => UserSelectors.selectPartyAccountsByAddressKey(state, selectedServiceAddress?.serviceAddressKey))
    const company = useCompany()

    const handleSelect = (address: UserProfileServiceAddress) => {
        selectServiceAddress(address.serviceAddressKey)
    }

    if (serviceAddresses.length === 1 && !selectedServiceAddress) {
        selectServiceAddress(serviceAddresses[0].serviceAddressKey)
    }

    const account = accounts.length ? accounts[0] : undefined
    const canRateChange = company.features.canMyAccountRateChange && account && account.accountType !== AccountTypeName.ChoiceIndustrial
    const isRenewal = account && canRenew(account, company.features.canRenewAnyStatus)
    const changeLabel = isRenewal ? 'Renew' : 'Change'
    return (
        <RatePlanPrefContainer background={lightLight}>
            {serviceAddresses.length > 0 && (
                <StyledAddressSelector>
                    <ServiceAddressForm
                        onSelect={handleSelect}
                        selectedServiceAddressKey={selectedServiceAddress?.serviceAddressKey}
                        serviceAddresses={serviceAddresses}
                    />
                </StyledAddressSelector>
            )}

            {account && account.status === AccountStatus.active && (
                <>
                    <TitleContainer>Current Rate</TitleContainer>
                    <BodyContainer>
                        <>
                            {account.productTypeCode === ProductTypeDescription.Variable ? (
                                <>{account.ratePlanName !== null ? account.ratePlanName : account.productTypeCode}</>
                            ) : (
                                <>
                                    {formatAsMoney(account.rate)} per {account.rateUnit}
                                    <br />
                                    {getProductTitleForAccount(account, company)}
                                    <br />
                                </>
                            )}
                            {account.termEndDate && <span>Expires {new Date(account.termEndDate).toLocaleDateString()}</span>}
                        </>
                    </BodyContainer>
                    {canRateChange && (
                        <CTAContainer>
                            <Button
                                data-testid={'rate-plan-change-button'}
                                onClick={() => {
                                    history.push(Routes.RateChange)
                                }}
                            >
                                {changeLabel}
                            </Button>
                            <br />
                            {!isRenewal && <div>(Early termination fee may apply)</div>}
                            {account.hasPendingContract && <div>(Pending contract changes)</div>}
                        </CTAContainer>
                    )}
                    {canRateChange && !isRenewal && (
                        <footer>
                            If you are on a discounted Military or Senior rate, please call{' '}
                            <PhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} /> to change your rate
                        </footer>
                    )}
                    {!canRateChange && (
                        <CTAContainer>
                            To change rate call <PhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} />
                        </CTAContainer>
                    )}
                </>
            )}
            {account && account.status !== AccountStatus.active && (
                <BodyContainer>
                    <InelligibleRateChangeMessage />
                </BodyContainer>
            )}
            {!account && <TitleContainer>Select an address to set your preferences</TitleContainer>}
        </RatePlanPrefContainer>
    )
}
