export const defaultCampaign = 'web-default'

export enum FeatureFlag {
    HomeWarranty = 'HomeWarranty',
    UtiltyChangeMessage = 'UtilityChangeMessage',
}

export enum SessionStorageVariables {
    PasswordResetToken = 'password-reset-token',
}

export const phoneNumbers = {
    emergency: '8772163447',
    enrollment: '8002804474',
    help: '8889950992',
    solarSupport: '8889740114',
    general: '8779954447',
}

export const emailAddresses = {
    netMeteringIllinois: 'NetMetering_Illinois@igs.com',
}

export const solarMyAccount = 'https://myaccount.igssolarpower.com/#/Login'

export enum ProductCodes {
    InsideLine = 'HP-INSIDE',
}

export const igsEnergy = 'IGS Energy'

export const businessHoursAbbreviated = 'Mon-Fri 8am-8pm | Sat 9am-3pm EST'
export const hwBusinessHoursAbbreviated = 'Mon-Fri 8am-6pm EST'

export const utilityBillVideoLink = 'https://www.youtube.com/watch?v=p_BsXwtTz7s'

const global = window as any

global.Igs = global.Igs || {}

const eventCodes = {
    zipCodeChanged: 'ZIP_CODE_CHANGED',
    offerRequestChanged: 'PRODUCT_REQUEST_INFO_CHANGED',
    loadingStart: 'LOADING_START',
    loadingEnd: 'LOADING_END',
    googleApiLoaded: 'GOOGLE_API_LOADED',
    userLoggedIn: 'USER_LOGGED_IN',
    userLoggedOut: 'USER_LOGGED_OUT',
}

global.Igs.EventCodes = eventCodes

export const EventCodes = eventCodes
