import { LineOfBusinessCode, UserProfile } from '@igs-web/common-models/models'
import { FullStoryIdentity } from '@igs-web/common-models/models/fullstory-identity-model'

import { Environment, getEnvironment } from './environment-utilities'

// FullStory Logging Documentation: https://help.fullstory.com/develop-js/251886-fs-log##search_query=

declare global {
    interface Window {
        readonly FS: {
            log(msg: string): void
            log(level: FsLogLevel, msg: string): void
            event(name: string, properties: any): void
            identify(userId: string, userProps: any): void
            (name: string, properties: any): void
        }
    }
}

const shouldLogToFullStory = (): boolean => {
    const environment = getEnvironment()
    const shouldLog = environment === Environment.Prod
    return shouldLog
}

export enum FsLogLevel {
    log = 'log',
    info = 'info',
    warn = 'warn',
    error = 'error',
    debug = 'debug',
}

export enum FsEvent {
    enrollmentException = 'enrollment-exception',
    matchingPartyFoundOfTypeOrganizationEnrollmentException = 'matching-party-found-of-type-organization-enrollment-exception',
    matchingPartyFoundOfTypeIndividualEnrollmentException = 'matching-party-found-of-type-individual-enrollment-exception',
    accountIsBlockedEnrollmentException = 'account-is-blocked-enrollment-exception',
    accountIsObsoleteEnrollmentException = 'account-is-obsolete-enrollment-exception',
    matchedAccountEnrollmentException = 'matched-account-enrollment-exception',
    saleConfirmation = 'sale-confirmation',
    offerSearch = 'offer-search',
    noOffers = 'no-offers-found',
    renewalActionClicked = 'renewal-action-clicked',
    renewalActionDisplayed = 'renewal-action-displayed',
    renewalSeeAllRates = 'renewal-see-all-rates',
    missingCampaignCodeText = 'missing-campaign-code-text',
    missingHWProductCodeText = 'missing-hw-product-code-text',
    genericError = 'generic-error',
    noMoveInDates = 'no-move-in-dates',
    creditCheckException = 'credit-check-exception',
    homeWarrantyProductSelection = 'home-warranty-product-selection',
    scanaAccountRegistrationError = 'scana-account-registration-error',
    downloadLatestInvoiceError = 'download-latest-invoice-error',
    onlineWebVerficiation = 'online-web-verification',
    nextPageClicked = 'next-wizard-page-click',
    previousPageClicked = 'previous-wizard-page-click'
}

export const logToFullStory = (level: FsLogLevel, message: string) => {
    if (shouldLogToFullStory()) {
        if (window.FS && window.FS.log) {
            window.FS.log(level, redactData(message))
        } else {
            console.warn('FullStory Logging not available')
            console.log(`${level}: ${redactData(message)}`)
        }
    } else {
        const log = {
            level,
            message,
        }
        console.log(log)
    }
}

export const fireCustomFullStoryEvent = (eventName: string, eventProps: unknown) => {
    const out = redactData(JSON.stringify(eventProps))
    let formattedProperties = null
    try {
        formattedProperties = JSON.parse(out)
    } catch(e) {
        formattedProperties = null
    } finally {
        const event = {
            name: eventName,
            properties: formattedProperties,
        }
        if (shouldLogToFullStory()) {
            if (window.FS) {
                window.FS('trackEvent', event)
            }
        } else {
            console.log(event)
        }
    }
}

export const identifyUserForFullStory = (userId: string, userProps: FullStoryIdentity) => {
    if (window.FS && window.FS.identify) {
        window.FS.identify(userId, userProps)
    }
}

export const mapToFullStoryIdentity = (userProfile: UserProfile): FullStoryIdentity => {
    return {
        ...userProfile,
        hasCommunitySolarSubscription: userProfile.communitySolarSubscriptions.length > 0 ? true : false,
        hasHomeProtection: userProfile.accounts.some(a => a.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty) ? true : false,
        hasElectric: userProfile.accounts.some(a => a.lineOfBusinessCode === LineOfBusinessCode.Electric) ? true : false,
        hasGas: userProfile.accounts.some(a => a.lineOfBusinessCode === LineOfBusinessCode.Gas) ? true : false,
    }
}

const redactData = (data: string): string =>
    data
        .replaceAll(/"cardNumber":"[0-9]*"/g, '"cardNumber": "Redacted"')
        .replaceAll(/"expirationMonth":"[0-9]*"/g, '"expirationMonth": "Redacted"')
        .replaceAll(/"expirationYear":"[0-9]*"/g, '"expirationYear": "Redacted"')
        .replaceAll(/"securityCode":"[0-9]*"/g, '"securityCode": "Redacted"')
        .replaceAll(/"bankAccountNumber":"[0-9]*"/g, '"bankAccountNumber": "Redacted"')
        .replaceAll(/"bankRoutingNumber":"[0-9]*"/g, '"bankRoutingNumber": "Redacted"')
        .replaceAll(/"socialSecurityNumber":"[0-9]*"/g, '"socialSecurityNumber": "Redacted"')
