export interface IgsComError extends Error {
    readonly message: string
    readonly isMessageUserPresentable: boolean
    readonly errorCode: PublicWebsiteErrorCode
}

export enum PublicWebsiteErrorCode {
    userNotFound = 'USER_NOT_FOUND',
    unknownServerError = 'UNKNOWN_SERVER_ERROR',
    unknownClientError = 'UNKNOWN_CLIENT_ERROR',
    clientOverride = 'CLIENT_OVERRIDE',
    invalidPasswordResetKey = 'INVALID_PASSWORD_RESET_KEY',
    badCredentials = 'BAD_CREDENTIALS',
    accountLocked = 'ACCOUNT_LOCKED',
    tokenMissing = 'TOKEN_MISSING',
    wrongScanaApplication = 'WRONG_SCANA_APPLICATION',
}

export const publicWebsiteError = (
    message: string,
    isMessageUserPresentable = false,
    errorCode: PublicWebsiteErrorCode = PublicWebsiteErrorCode.unknownClientError,
    error?: Error,
): IgsComError => ({
    message,
    isMessageUserPresentable,
    errorCode,
    name: error ? error.name : errorCode,
})
