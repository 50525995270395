import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import styled from 'styled-components'
import { withMask } from 'use-mask-input'

import { FieldType } from '@igs-web/common-models/models/field-type'
import { FieldWrapper } from '@igs-web/common-ui-components/_atoms/forms/field-wrapper'
import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { convertToClassNames } from '@igs-web/common-utilities/utilities/css-class-utilities'

import { CustomInputProps, TypedField, TypedFieldProps } from './typed-field'

type Props = FieldRenderProps<FieldType, HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement> & CustomInputProps & AdditionalProps

const StyledActionLink = styled(ActionLink)`
    position: absolute;
    top: 12px;
    right: 30px;
    font-size: 14px;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }
`

const InputFieldComponent = (props: Props): JSX.Element => {
    const { doNotRecord, hideError, mask, action, button, className, customError, wrapperClass, input, meta, label, placeholder, dataTestId, ...rest } = props
    const getInputClasses = (): string => convertToClassNames({ 'is-invalid': meta.error && meta.touched }, 'input-field', 'form-control', className || '')

    const inputProps = {
        ...rest,
        ...input,
        placeholder: placeholder || ' ',
        className: getInputClasses(),
        'data-testid': dataTestId,
    }

    const inputComponent = mask ? <input ref={withMask(mask)} {...inputProps} /> : <input {...inputProps} />

    return (
        <FieldWrapper
            hideError={hideError}
            touched={meta.touched}
            wrapperClass={wrapperClass}
            button={button}
            customError={customError}
            label={label}
            id={rest.id}
            error={meta.error}
            doNotRecord={doNotRecord}
        >
            {inputComponent}
            {action ? (
                <StyledActionLink className="input-action" onClick={action.effect} tabIndex={-1}>
                    {action.label}
                </StyledActionLink>
            ) : null}
        </FieldWrapper>
    )
}

interface AdditionalProps {
    readonly mask?: string
    readonly button?: React.ReactNode
    readonly action?: {
        readonly label: string | JSX.Element
        readonly effect: () => void
    }
}

export type InputFieldProps<T> = TypedFieldProps<T> & AdditionalProps

export const InputField = <T extends object>(props: InputFieldProps<T>): JSX.Element => {
    return <TypedField<T> component={InputFieldComponent} {...props} />
}
