import { FieldType } from '@igs-web/common-models/models/field-type'
import * as React from 'react'
import { Field, FieldProps } from 'react-final-form'

export interface CustomInputProps {
    readonly label?: string | JSX.Element
    readonly wrapperClass?: string
    readonly customError?: string | JSX.Element
    readonly hideError?: boolean
    readonly id: string
    // TODO 6/12/20: convert id's to dataTestId then delete id's
    readonly dataTestId?: string
    readonly disabled?: boolean
    readonly validateIfDisabled?: boolean
    readonly doNotRecord?: boolean
}

export type TypedFieldProps<T> = FieldProps<FieldType, HTMLElement> & { readonly path?: string; readonly name: keyof T } & CustomInputProps

export const TypedField = <T extends object>({ path, name, disabled, validateIfDisabled, validate, ...rest }: TypedFieldProps<T>): JSX.Element => {
    const fullName = path ? `${path}.${name}` : name
    const validator = disabled && !validateIfDisabled ? undefined : validate

    return <Field {...rest} disabled={disabled} validate={validator} name={fullName} />
}
