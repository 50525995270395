import { MembershipApplication } from '@igs-web/common-models/constants/membership-application'
import { LineOfBusinessCode } from '@igs-web/common-models/models'

import { CompanyFeatures } from './companyFeatures'
import { CompanyVerbiage } from './companyVerbiage'
import { EnrollmentFeatures } from './enrollmentFeatures'
import { RouteFeatures } from './routeFeatures'

export enum SupportedCompanies {
    Igs = 'IGS',
    Scana = 'SCANA',
    ScanaRegulated = 'SCANAREGULATED',
}

//if BaseCompany includes a property of type JSX.Element, this file, and files that define a basecompany will need to import React
//and have their extension changed from .ts to .tsx
export interface BaseCompany {
    readonly name: SupportedCompanies | ''
    readonly displayName: string
    readonly displayNameByLob: (lob: LineOfBusinessCode | undefined) => string
    readonly businessHours: BusinessHours
    readonly phoneNumber: CompanyPhoneNumbers
    readonly myAccountUrl: string
    readonly externalWebsite: string
    readonly verbiage: CompanyVerbiage
    readonly features: CompanyFeatures
    readonly includeNonBreakingHyphenInTermMonths: boolean
    readonly includeLobTitleInProductTitle: boolean
    readonly useCompanyCommodityTitle: boolean
    readonly graphics: Graphics
    readonly address: string
    readonly lockboxPaymentCompanyName?: string
    readonly lockboxPaymentPOBox?: string
    readonly lockboxPaymentCityStateZip?: string
    readonly emails: Emails
    readonly enrollment: EnrollmentFeatures
    readonly routes: RouteFeatures
    readonly companionWebsite?: CompanionWebsite
    readonly membershipApplicationName: MembershipApplication | ''
}

interface Emails {
    readonly customerSupport: string
    readonly communitySolar: string
    readonly solarSupport: string
    readonly homeWarranty: string
}

interface CompanionWebsite {
    readonly displayName: string
    readonly myAccountUrl: string
}

interface CompanyPhoneNumbers {
    readonly help: string
    readonly gasLeak: string
    readonly heatProgram: string
    readonly customerSupport: string
    readonly commecialSupport: string
    readonly payment?: string
    readonly communitySolar: string
    readonly solarSupport: string
    readonly homeWarranty: string
    readonly supportFax: string
}

interface Graphics {
    readonly colorLogo: string
    readonly monochromeLogo: string
    readonly loginBackground: string
}
interface BusinessHours {
    readonly businessHoursFull: string
    readonly businessHoursAbbreviated: string
    readonly businessHoursAbbreviatedWeek: string
    readonly businessHoursAbbreviatedWeekend: string
    readonly homwWarrantyAbbreviated: string
    readonly homeWarrantyFull: string
}
