import styled from 'styled-components'

const defaultSize = '1.5em'
const defaultThickness = '1px'

export const CloseButton = styled.button<CloseButtonProps>`
    --size: var(--close-button-size, ${defaultSize});
    --thickness: var(--close-button-thickness, ${defaultThickness});
    min-width: var(--size);
    min-height: var(--size);
    width: var(--size);
    height: var(--size);
    padding: 0px;
    cursor: pointer;
    border: none;
    outline: none;
    position: relative;

    &:focus,
    &:hover {
        transform: scale(1.1);
        outline: none;
    }

    &.active {
        transform: scale(1.2);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0.4em;
        left: 0;
        width: 100%;
        height: var(--thickness);
        transform: rotate(45deg);
        background: ${({ $color }) => $color};
    }
    &:after {
        content: '';
        position: absolute;
        top: 0.4em;
        left: 0;
        width: 100%;
        height: var(--thickness);
        transform: rotate(-45deg);
        background: ${({ $color }) => $color};
    }
`

interface CloseButtonProps {
    readonly $color: string
}
