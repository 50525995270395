import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { SvgSize } from '@igs-web/common-ui-components/_atoms/SVGs/svg-icon'
import { SectionHeadline } from '@igs-web/common-ui-components/_atoms/typography'
import { ContainerGrid } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { bodyColor, fontSizeHeading, fontSizeLarge, maxContentWidth, primaryDark, primaryLight } from '@igs-web/common-ui-components/styles/theme'

const logo = 'logo'
const topNav = 'topNav'
const mainNav = 'mainNav'

export const MyAccountBtn = styled.div`
    text-decoration: none;
    color: ${primaryLight};
    fill: ${primaryLight};
    max-height: 135px;
    font-weight: ${FontWeight.Medium};
    cursor: pointer;
    :hover {
        color: ${primaryDark};
        border-color: ${primaryDark};
        transition: 100ms;
        fill: ${primaryDark};
    }
    svg {
        height: ${SvgSize.XxSmall};
        width: auto;
    }
`
export const NoNavHeaderContainer = styled(ContainerGrid)`
    display: grid;
    width: 100%;
    grid-template-rows: 80px;
    max-width: ${maxContentWidth};
`
export const HeaderContainer = styled.div`
    && {
        grid-column: 1/-1;
    }
    display: grid;
    position: relative;
    width: ${Breakpoint.LargeDesktop};
    margin-inline: auto;
    margin-block-start: ${Spacing.Medium};
    padding: 0 ${Spacing.Medium};
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 50px min-content;
    row-gap: ${Spacing.Large};
    grid-template-areas:
        '${logo} ${logo} ${logo} ${topNav} ${topNav} ${topNav} ${topNav} ${topNav}'
        '${logo} ${logo} ${logo} ${mainNav} ${mainNav} ${mainNav} ${mainNav} ${mainNav}';

    @media (max-width: ${Breakpoint.LargeDesktop}) {
        width: 100%;
    }
`
const Logo = styled.img`
    max-width: 270px;
    padding: 0 ${Spacing.Medium};
`
const LogoLink = styled(Link)`
    grid-area: ${logo};
`
export const HeaderLogo = () => {
    const company = useCompany()

    return (
        <LogoLink href={company.externalWebsite}>
            <Logo src={company.graphics.colorLogo} />
        </LogoLink>
    )
}

export const TopNavContainer = styled.div`
    display: grid;
    grid-area: ${topNav};
    grid-auto-flow: column;
    justify-items: end;
    column-gap: ${Spacing.Large};
    justify-self: end;
    align-items: center;
`
const topNavCss = css`
    color: ${primaryLight};
    font-weight: 600;
    text-decoration: none;
    :hover {
        color: ${primaryDark};
        transition: 200ms;
    }
`

export const TopNavLink = styled(Link)`
    ${topNavCss}
`

export const TopNavRouterLink = styled(RouterLink)`
    ${topNavCss}
`

export const MainNavContainer = styled.div`
    display: grid;
    grid-area: ${mainNav};
    justify-items: end;
    column-gap: 40px;
    grid-template-columns: repeat(4, max-content);
    justify-self: end;
`

export const NavBannerHeader = styled(SectionHeadline)`
    color: ${bodyColor};
    font-weight: ${FontWeight.Default};
    line-height: 1.33;
    font-size: ${fontSizeLarge};
    @media (min-width: ${Breakpoint.Tablet}) {
        font-size: ${fontSizeHeading};
    }
`
export const NavLinks = styled.nav`
    width: 100%;
    ul {
        display: flex;
        list-style-type: none;
        justify-content: space-between;
        margin: 0;
        padding-inline: 0;
        font-weight: ${FontWeight.SemiBold};
        a {
            color: unset;
        }
    }
    display: none;
    @media (min-width: ${Breakpoint.Tablet}) {
        display: block;
    }
`
