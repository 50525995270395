import React from 'react'
import { TableInstance } from 'react-table'
import styled from 'styled-components'

import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { rangeInclusive } from '@igs-web/common-utilities/utilities/array-utilities'

// const StyledButton = styled.button`
//     background: none !important;
//     border: none !important;
//     border-width: 0px;
//     padding: 0 !important;
//     /*input has OS specific font-family*/
//     text-decoration: none;
//     cursor: pointer;
// `

// const StyledLink = styled.a<StyledLinkProps>`
//     text-decoration: ${({ $selected }) => ($selected ? 'underline' : 'none')};
//     user-select: none;
//     :hover {
//         text-decoration: underline;
//     }
// `

const NavigationLink = styled(ActionLink)`
    user-select: none;
    cursor: pointer;
`

const ActiveNavigationLink = styled(NavigationLink)`
    text-decoration: underline;
`

const PageSelectLink = ({ pageIndex, activePageIndex, goToPage }: PageSelectLinkProps): JSX.Element => {
    const isActive = activePageIndex === pageIndex
    const goToThisPage = () => goToPage(pageIndex)
    const pageNumberDisplay = pageIndex + 1
    return isActive ? (
        <ActiveNavigationLink onClick={goToThisPage}>{pageNumberDisplay}</ActiveNavigationLink>
    ) : (
        <NavigationLink onClick={goToThisPage}>{pageNumberDisplay}</NavigationLink>
    )
}

interface PageSelectLinkProps {
    readonly activePageIndex: number
    readonly pageIndex: number
    readonly goToPage: (pageIndex: number) => void
}

const OmittedPageSpacer = () => <span>...</span>

// interface StyledLinkProps {
//     readonly $selected: boolean
// }

// const StyledPageLinks = styled.div`
//     text-align: center;
//     margin: 20px;
//     cursor: pointer;
//     a {
//         margin: 10px;
//         color: var(--body-color);
//     }
//     ${StyledLink} + ${StyledLink} {
//         &::before {
//             content: '|';
//             margin-right: 15px;
//         }
//     }
// `

const StyledPageNavigation = styled.div`
    text-align: center;
    margin: ${Spacing.Medium};

    > * {
        margin: ${Spacing.Small};
    }
`

const surroundingPageCount = 3

const getPageIndexes = (currentPageIndex: number, lastPageIndex: number): ReadonlyArray<number> => {
    const rangeStart = Math.max(currentPageIndex - surroundingPageCount, 0)
    const rangeEnd = Math.min(currentPageIndex + surroundingPageCount, lastPageIndex)
    return rangeInclusive(rangeStart, rangeEnd)
}

export const PageNavigation = ({ instance }: PageNavigationProps) => {
    const { pageCount, nextPage, previousPage, gotoPage } = instance
    const currentPageIndex = instance.state.pageIndex

    const hasResults = !!pageCount

    const firstPageIndex = 0
    const lastPageIndex = Math.max(pageCount - 1, 0)

    const pageIndexes = getPageIndexes(currentPageIndex, lastPageIndex)

    const hasOmittedPrevious = firstPageIndex + 1 < Math.min(...pageIndexes)
    const hasOmittedNext = lastPageIndex - 1 > Math.max(...pageIndexes)

    return (
        <StyledPageNavigation>
            {hasResults && (
                <>
                    <NavigationLink onClick={previousPage}>{'<'}</NavigationLink>
                    {!pageIndexes.includes(firstPageIndex) && (
                        <PageSelectLink activePageIndex={currentPageIndex} pageIndex={firstPageIndex} goToPage={gotoPage} />
                    )}
                    {hasOmittedPrevious && <OmittedPageSpacer />}
                    {pageIndexes.map(page => (
                        <PageSelectLink activePageIndex={currentPageIndex} pageIndex={page} key={page} goToPage={gotoPage} />
                    ))}
                    {hasOmittedNext && <OmittedPageSpacer />}
                    {!pageIndexes.includes(lastPageIndex) && (
                        <PageSelectLink activePageIndex={currentPageIndex} pageIndex={lastPageIndex} goToPage={gotoPage} />
                    )}
                    <NavigationLink onClick={nextPage}>{'>'}</NavigationLink>
                </>
            )}
        </StyledPageNavigation>
    )
}

interface PageNavigationProps {
    readonly instance: TableInstance
}
