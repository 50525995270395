import * as React from 'react'

export enum Verbiage {
    billsTableAmountDueHeaderText,
    usageTableHeadersQuantity,
    usageTableHeadersChargesPerUnit,
    paymentButtonText,
    paymentNavigationText,
    paperlessCommunicationMessage,
    creditCheckVerificationMessage,
    changeEmail,
    loginUserNotFound,
    tooManyLoginAttempts,
}

export const CompanyVerbiage = ({ verbiage }: Props) => {
    if (typeof verbiage === 'function') {
        return verbiage()
    }
    return verbiage
}
interface Props {
    verbiage: React.ReactNode | React.ReactElement
}

export type CompanyVerbiage = Partial<Record<Verbiage, React.ReactNode | React.ReactElement>>
