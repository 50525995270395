import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import {
    BankAccountFields,
    CreditCardFields,
    PaymentMethodModel,
    PaymentOption,
    PaymentType,
    UtilityBillFields,
    achId,
    creditCardId,
    utilityBillId,
} from '@igs-web/common-models/models/payment-model'

import { conditionalArray } from '../utilities/array-utilities'

export const getPaymentTitle = (payment: PaymentMethodModel): string => {
    switch (payment.paymentType) {
        case PaymentType.Ach:
            return 'Electronic Check'
        case PaymentType.CreditCard:
            return 'Credit Card'
        case PaymentType.UtilityBill:
            return 'Utility Bill'
        default:
            throw Error(`Payment Type ${payment.paymentType} is not recognized`)
    }
}

export const getLongPaymentDescription = (payment: PaymentMethodModel): string => {
    /* eslint-disable no-case-declarations */
    switch (payment.paymentType) {
        case PaymentType.Ach:
            const { bankAccountNumber, bankRoutingNumber } = payment as BankAccountFields
            const accountNumberDisplay = bankAccountNumber.substring(bankAccountNumber.length - 4)
            return `ACH ${bankRoutingNumber} ${accountNumberDisplay}`
        case PaymentType.CreditCard:
            const { cardNumber, expirationYear, expirationMonth } = payment as CreditCardFields
            const cardNumberDisplay = cardNumber.substring(cardNumber.length - 4)
            return `Credit Card # ending ${cardNumberDisplay} with exp ${expirationMonth}/${expirationYear}`
        case PaymentType.UtilityBill:
            const { billingUtilityCode, billingUtilityAccountNumber } = payment as UtilityBillFields
            return `${billingUtilityCode} - ${billingUtilityAccountNumber}`
        default:
            throw Error(`Payment Type ${payment.paymentType} is not recognized`)
    }
    /* eslint-enable no-case-declarations */
}

export const getPaymentDescription = (payment: PaymentMethodModel): string => {
    /* eslint-disable no-case-declarations */
    switch (payment.paymentType) {
        case PaymentType.Ach:
            const { bankAccountNumber } = payment as BankAccountFields
            const accountNumberDisplay = bankAccountNumber.substring(bankAccountNumber.length - 4)
            return `xxxxxxx${accountNumberDisplay}`
        case PaymentType.CreditCard:
            const { cardNumber } = payment as CreditCardFields
            const cardNumberDisplay = cardNumber.substring(cardNumber.length - 4)
            return `xxxx-xxxx-xxxx-${cardNumberDisplay}`
        case PaymentType.UtilityBill:
            const { billingUtilityCode, billingUtilityAccountNumber } = payment as UtilityBillFields
            return `${billingUtilityCode} - ${billingUtilityAccountNumber}`
        default:
            throw Error(`Payment Type ${payment.paymentType} is not recognized`)
    }
    /* eslint-enable no-case-declarations */
}

export const getPaymentIdenifier = (payment?: PaymentMethodModel): string => {
    if (!payment) {
        return ''
    }
    switch (payment.paymentType) {
        case PaymentType.Ach:
            return (payment as BankAccountFields).bankAccountNumber
        case PaymentType.CreditCard:
            return (payment as CreditCardFields).cardNumber
        case PaymentType.UtilityBill:
            return (payment as UtilityBillFields).billingUtilityAccountNumber
        default:
            throw Error(`Payment Type ${payment.paymentType} is not recognized`)
    }
}

export const getPaymentOptionsWithPrevious = (
    allowedPaymentTypes: ReadonlyArray<PaymentType>,
    previousPaymentMethods: ReadonlyArray<PaymentMethodModel>,
    showNewUtilityBillOption: boolean,
): ReadonlyArray<PaymentOption> => {
    const allowAch = allowedPaymentTypes.includes(PaymentType.Ach)
    const allowCreditCard = allowedPaymentTypes.includes(PaymentType.CreditCard)
    const allowUtilityBill = allowedPaymentTypes.includes(PaymentType.UtilityBill)

    return [
        ...conditionalArray(allowCreditCard, { name: 'Credit Card', existing: false, paymentType: PaymentType.CreditCard, id: creditCardId }),
        ...conditionalArray(allowAch, { name: 'Electronic Check', existing: false, paymentType: PaymentType.Ach, id: achId }),
        ...conditionalArray(allowUtilityBill && showNewUtilityBillOption, {
            name: 'Utility Bill - New',
            existing: false,
            paymentType: PaymentType.UtilityBill,
            id: utilityBillId,
        }),
        ...previousPaymentMethods
            .filter(p => allowedPaymentTypes.includes(p.paymentType))
            .map(p => ({
                name: getPaymentTitle(p) + ' - ' + getPaymentDescription(p),
                paymentType: p.paymentType,
                existing: true,
                id: JSON.stringify(p),
                payment: p,
            })),
    ]
}

export const selectedUtilityBillOptions = (selectedOffers: ReadonlyArray<OfferModel>) => {
    const supportedUlobs = selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)?.primaryProduct.supportedUtilities

    return supportedUlobs?.map(u => selectedOffers.filter(o => u.utilityLineOfBusinessCode === o.primaryProduct.ulob?.utilityLineOfBusinessCode))
}
