import _isArray from 'lodash-es/isArray'
import _isDate from 'lodash-es/isDate'
import _isFunction from 'lodash-es/isFunction'
import _isObject from 'lodash-es/isObject'
import { getFromStorage, putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

const cacheKeyPrefix = 'API_CACHE'

const createCacheKey = (...args: ReadonlyArray<any>) => {
    const values = args.map((arg): string => {
        if (_isArray(arg)) {
            return arg.map(a => createCacheKey(a)).join(':')
        } else if (_isDate(arg)) {
            return '' + arg.getTime()
        } else if (_isObject(arg)) {
            return Object.keys(arg)
                .sort()
                .map(k => createCacheKey(arg[k]))
                .join(':')
        } else if (_isFunction(arg)) {
            return ''
        } else {
            return arg ? arg.toString() : ''
        }
    })

    return values.join(':')
}

export const cache = <TFun extends Function>(key: string, fn: TFun, ignoreCache = false): TFun => {
    return (async (...args: ReadonlyArray<any>) => {
        const fullCacheKey = `${cacheKeyPrefix}::${key}::${createCacheKey.apply({}, args)}`

        if (!ignoreCache) {
            const cachedValue = getFromStorage(fullCacheKey)
            if (cachedValue) {
                return cachedValue
            }
        }

        const result = await fn.apply({}, args)
        putInStorage(result, fullCacheKey)
        return result
    }) as any
}
