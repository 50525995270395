import * as React from 'react'

import Chart from 'chart.js'

import { ChoicePayment } from '@igs-web/common-models/models'
import { LegacyPayment } from '@igs-web/common-models/models/legacy-data'
import { BarChart } from '@igs-web/common-ui-components/_molecules/charts/bar-chart'
import { DefaultMoneyPrecision, formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { months } from '@igs-web/common-utilities/utilities/date-utilities'
import { precisionRound } from '@igs-web/common-utilities/utilities/number-utilites'

import { dedupeChoicePayments } from './business/billing-service'

const getValueFromCustomProperty = (property: string): string => {
    return window.getComputedStyle(document.documentElement).getPropertyValue(property)
}

const getPaymentAmounts = (payments: ReadonlyArray<ChoicePayment>, legacyPayments: ReadonlyArray<LegacyPayment>) => {
    const dedupedPayemnts = dedupeChoicePayments(payments)
    const currentDate = new Date()
    const startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth() + 1, 5) //deal with edge cases

    return Array(months.length)
        .fill(0)
        .map((_, index) => {
            const compareDate: Date = new Date(startDate.getFullYear(), startDate.getMonth() + index, startDate.getDate())
            const year = compareDate.getFullYear()
            const month = compareDate.getMonth()

            const choicePaymentsTotal =
                -1 *
                dedupedPayemnts
                    .filter(x => {
                        const paymentDate = new Date(x.date)
                        return paymentDate.getFullYear() === year && paymentDate.getMonth() === month
                    })
                    .reduce((acc, cur) => acc + cur.amount, 0)

            const legacyPaymentsTotal = legacyPayments
                .filter(x => {
                    const paymentDate = new Date(x.date)
                    return paymentDate.getFullYear() === year && paymentDate.getMonth() === month
                })
                .reduce((acc, cur) => acc + cur.amount, 0)

            return precisionRound(choicePaymentsTotal + legacyPaymentsTotal, DefaultMoneyPrecision)
        })
}

export const PaymentHistoryChart = ({ payments, legacyPayments }: Props): JSX.Element => {
    const xLabel = 'MONTH'
    const yLabel = 'AMOUNT'
    const startMonth = new Date().getMonth() + 1

    const data = getPaymentAmounts(payments, legacyPayments ?? [])
    const datasets = [
        {
            type: 'bar',
            backgroundColor: getValueFromCustomProperty('--primary-base'),
            label: 'Payments by Month',
            data,
        },
    ]

    const chartData: Chart.ChartData = {
        datasets,
        labels: months
            .slice(startMonth, months.length)
            .concat(months.slice(0, startMonth))
            .map(m => m.abbreviation),
    }
    const chartOptions: Chart.ChartOptions = {
        tooltips: {
            callbacks: {
                label: tooltipItem => {
                    if (tooltipItem && tooltipItem.value) {
                        return formatAsMoney(Number(tooltipItem.value))
                    }
                    return ''
                },
            },
        },
    }

    return (
        <div data-testid="payment-history-chart" className="hide-on-mobile" style={{ height: '300px', width: '100%' }}>
            <BarChart data={chartData} xLabel={xLabel} yLabel={yLabel} options={chartOptions} />
        </div>
    )
}

interface Props {
    readonly payments: ReadonlyArray<ChoicePayment>
    readonly legacyPayments?: ReadonlyArray<LegacyPayment>
}
