/* eslint-disable max-len */
import * as React from 'react'
import { Link } from 'react-router-dom'

import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'

import { CreateAccount, ForgotPassword } from 'domain/routes'

import { LegalRoutes } from '../../legal/legal-routes'
import { CompanyVerbiage, Verbiage } from '../companyVerbiage'

const PaperlessCommunicationMessage = () => (
    <>
        <p>
            Yes, I want to receive my account and service related communications by email, and I verify that the email address I’ve provided is my primary
            email.
        </p>
        <p className="footnote">
            By checking this box, you agree to the{' '}
            <ExternalLink href={LegalRoutes.electronicCommunicationConsent().toString()}>Consent for Electronic Communications</ExternalLink>
        </p>
    </>
)

export const scanaVerbiage: CompanyVerbiage = {
    [Verbiage.paymentButtonText]: 'Make a Payment',
    [Verbiage.paymentNavigationText]: 'Ways to Pay',
    [Verbiage.billsTableAmountDueHeaderText]: 'Billed Amount',
    [Verbiage.usageTableHeadersQuantity]: 'Consumption',
    [Verbiage.usageTableHeadersChargesPerUnit]: 'Price per Therm',
    [Verbiage.paperlessCommunicationMessage]: PaperlessCommunicationMessage,
    [Verbiage.creditCheckVerificationMessage]:
        'Please verify that your full legal name was entered and that your social security number was entered correctly before proceeding.',
    [Verbiage.changeEmail]: 'Your email is used for communication with SCANA Energy/SCANA Energy Regulated Division and to log in to My Account.',
    [Verbiage.loginUserNotFound]: (
        <>
            The username entered is not correct. If you have not yet logged in using your email address, please click <Link to={CreateAccount}>here</Link> to
            change your username to your email address. You’ll be asked to change your password as well.
        </>
    ),
    [Verbiage.tooManyLoginAttempts]: (
        <>
            Too many logins attempted. Please <Link to={ForgotPassword}>reset your password</Link> to unlock.
        </>
    ),
}
